import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { GracePeriodService } from 'src/app/services/grace-period.service';
import { ShareFileService } from 'src/app/services/share-file.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import { LiveNotificationsService } from 'src/app/services/live-notifications.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-edit-share-detail',
  templateUrl: './edit-share-detail.component.html',
  styleUrls: ['./edit-share-detail.component.scss']
})
export class EditShareDetailComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'email', 'date', 'status', 'viewCount', 'deleted', 'unshared', 'gracePolicy', 'expirationDate', 'downloadable'];
  selection = new SelectionModel<any>(true, []);

  emailValue: any;
  authority: any;
  gracePeriodArray: any;
  sharedFiles: any;
  selectedArr: any[] = [];
  auditTrail: any[] = [];
	finalListArr = new MatTableDataSource<any>();
  downloadable: any;
  idFile: any;
  filename: any;
  key: any;
  subscriptionObject: Subscription;

  constructor(
    private authService: AuthenticationService,
    private shareFilesService: ShareFileService,
    private cookieService: CookieService,
    private data: SharedDataService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    public liveNotificationService: LiveNotificationsService,
  ) { }



  ngOnInit() {
    this.getRealNotifications();
    this.emailValue = this.cookieService.get('email');
    this.authority = this.cookieService.get('role');
    this.idFile = this.route.snapshot.paramMap.get('id');
    this.retrieveSharedFiles();
  }

  getRealNotifications() {
    this.subscriptionObject = this.liveNotificationService
      .getNotifications()
      .subscribe((data: any) => {
        this.retrieveSharedFiles();
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.finalListArr.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.selectedArr = [];
    this.isAllSelected() ?
        this.selection.clear() :
        this.finalListArr.data.forEach((row) => {
            this.selection.select(row);
            if (row) {
              this.selectedArr.push(row);
            }
        });
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  singleRowSelected(event, row, selection) {
    if (event && event.checked === true) {
        this.selection.select(row);
        this.selectedArr.push(row);
    } else if (event && event.checked === false) {
        this.selectedArr.map((item, index) => {
            if (event.source.value === item.email) {
              this.selectedArr.splice(index, 1);
              if(this.selectedArr.length === 0) {
                this.masterToggle();
              }
            }
        });
    } else {
        return null;
    }
  }

  unShareFilesList() {
    var unShareUserList = [];
    this.selectedArr.forEach(element => {
      unShareUserList.push(element.useremail);
    });
    this.shareFilesService.unShareFile(unShareUserList, this.emailValue, this.key, this.filename).subscribe(result => {
        if(result){
          this.snackBar.open(result['message'], 'Close');
          this.liveNotificationService.sendNotification({
            'email': this.emailValue,
            'key': this.key,
            'status': 'unshare'
          });
          this.retrieveSharedFiles();
        }
    });
  }

  // UTILS:
  private retrieveSharedFiles() {
    this.shareFilesService.listUserSharedFiles(this.emailValue).subscribe(result => {
      if (result) {
        this.sharedFiles = result['payload'];
        // this.sharedFilesCount = this.sharedFiles.length;
        var arr = [];
        this.sharedFiles.forEach(item => {
          if(item.key === this.idFile) {
            this.filename = item.filename;
            this.key = item.key;
            arr.push(item);
          }
        });
        this.finalListArr = new MatTableDataSource(arr);
        this.getFileAuditTrail();
      }
    });
  }

  private getFileAuditTrail() {
    this.authService.getFileTrail(this.key).subscribe(result => {
      if (result) {
        this.auditTrail = JSON.parse(result['actions']['data']);
      }
    })

  }

  goToDashboard(){
    this.router.navigateByUrl('/dashboard');
  }

  setDownloadable(event, key, useremail) {
    this.downloadable = event.checked;
    this.shareFilesService.fileDownloadableByKey(key, useremail, this.downloadable).subscribe((result) => {
      if(result['status'] === 200){
        this.liveNotificationService.sendNotification({
          'email': this.emailValue,
          'key': key,
          'status': 'set_downloadable'
        });
        this.snackBar.open(`File downloadable ${event.checked === false ? 'disabled' : 'enabled'} successfully`, 'Close')
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionObject.unsubscribe();
  }

}
