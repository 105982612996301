import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
export var server: string = '/api';
//export var server: string = 'http://localhost:3000/api';
@Injectable()
export class GlobalApiIntegration {
    constructor(
        private http: HttpClient
    )
    {}
}