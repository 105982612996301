import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CuratusAuthGuardService implements CanActivate {

  authenticated: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(): any {
    if (this.authenticationService.loginUserToken()) {
      this.authenticated = true;
      return true;
    } else {
      this.router.navigate(['/login']);
      this.authenticated = false;
      return false;
    }
  }

  checkAuthentication() {
    if (this.isAuthenticated()) {
      if (localStorage.getItem('ROLE') === 'Organization Admin') {
        this.router.navigateByUrl('/curatus-mvp-admin');
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    }
  }

  isAuthenticated(): boolean {
    return this.authenticationService.loginUserToken() !== null ? true : false;
  }

  isNavBar(): boolean {
    return this.authenticationService.navBar();
  }

  isAdmin(): boolean {
    if (this.authenticationService.roleBased() === 'Organization Admin') {
      return true;
    } else if (this.authenticationService.roleBased() === 'User') {
      return false;
    }
  }

  isSuperAdmin(): boolean {
    if (this.authenticationService.individualEmail() === 'admin@curatus.io') {
      return true;
    } else{
      return false;
    }
  }

  isConsultant(): boolean {
    if (this.authenticationService.roleBased() === 'Individual') {
      return true;
    } else {
      return false;
    }
  }

  adminEmail(): any {
    return this.authenticationService.individualEmail();
  }
}
