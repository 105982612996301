import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-logout-dialog',
    templateUrl: './download-dialog.component.html',
    styleUrls: ['../delete-file/delete-file.component.css']
})
export class DownloadDialogComponent {
    public notifyStatus: any;
    public confirmMessage: string;
    public title: string;
    public button: ButtonModel;
    public files: [];
    constructor(
        public dialogRef: MatDialogRef<DownloadDialogComponent>,
        private cookieService: CookieService,
        private authService: AuthenticationService,
        private router: Router
        ) {
        this.button = {
            confirm: true,
            confirmText: 'Yes'
        };
    }

    onSave() {
        this.authService.downloadCompressedFile(this.files).subscribe(data => {
            var blob = new Blob([data])
            saveAs(blob, 'download.zip');
        })
        console.log('zip file download')
        this.dialogRef.close();
    }
}

export class ButtonModel {
    confirm?: boolean;
    cancel?: boolean;
    close?: boolean;
    confirmText?: string;
}
