import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ShareFileService} from '../../../services/share-file.service';

@Component({
    selector: 'app-delete-file',
    templateUrl: './delete-file.component.html',
    styleUrls: ['./delete-file.component.css']
})

export class DeleteFileComponent implements OnInit {
    userEmailId: any;
    filename: any;
    email: any;
    rejectAction: any;
    deleteAction: any;
    key: any;
    notifyStatus: any;
    setRetention: any;
    sliderDisabled: boolean;
    now = new Date();
    retentionPeriod: boolean;
    noRetentionPeriod: boolean;
    retentionValue: any;
    policySet: any;
    maxDays: any;

    retentionForm: FormGroup = new FormGroup({
        retentionPolicy: new FormControl('', [Validators.required]),
      });

    constructor(
        private shareFilesService: ShareFileService,
        private cookieService: CookieService,
        private authService: AuthenticationService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DeleteFileComponent>,
        private snackBar: MatSnackBar
    ) {
        this.setRetention = {};
        this.retentionValue = 3;
    }

    formatLabel(value: number) {
        if (value >= 1) {
          return Math.round(value / 1) + 'y';
        }
        return value;
    }

    sliderOnChange(value: any, event: any) {
        console.log('changed: ', value, event);
        this.setRetention.retentionDays = '';
        this.now = new Date();
        this.now.setDate(this.now.getDate() + value);
        this.retentionValue = value;
        this.retentionPeriod = true;
        this.setRetention.retentionDays = new Date(this.now).toLocaleDateString("en-us")
        console.log(this.setRetention.retentionDays);
    }

    retentionPolicy(status: string) {
        if (status === 'setRetention') {
            this.sliderDisabled = false;
            this.retentionPeriod = true;
            this.noRetentionPeriod = false;
            this.policySet = true;
            this.now.setDate(this.now.getDate() + 3);
            this.setRetention.retentionDays = new Date(this.now).toLocaleDateString("en-us")
        } else if (status === 'noRetention') {
            this.sliderDisabled = true;
            this.retentionPeriod = false;
            this.noRetentionPeriod = true;
            this.policySet = false;
        }
    }

    fileDelete(policy: any) {
        if (this.retentionPeriod === true || this.noRetentionPeriod === true) {
            this.authService.deleteFiles(this.userEmailId, this.filename, this.key, this.retentionValue, this.policySet).subscribe(data => {
                console.log(data);
                const newObj = {};
                newObj['policySet'] = this.policySet;
                newObj['action'] = 'Submit';
                console.log('New Obj', newObj);
                this.dialogRef.close(newObj);
                this.snackBar.open('File has been deleted and specified retention policy successfully.', 'Close');
            }, error => {
                this.snackBar.open(error.error.message)
            });
        } else {
            this.snackBar.open('Please select any retention policy or close it', 'Close')
        }
    }

    ngOnInit() {
        this.userEmailId = this.cookieService.get('email');
        this.sliderDisabled = true;

        this.authService.getRetentionPeriod(this.userEmailId).subscribe(result => {
            console.log(result);
            this.maxDays = result.policy.retention.timePeriod;
        });
    }
}
