import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { userLogin, userRegister } from "../Screens/authentication/authentication.model";
import * as glob from './global-url.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";

@Injectable({ providedIn: "root" })

export class AuthenticationService {
  private AuhtorizationServer = glob.server
  private rejectedFilesListUrl = '/v1/sharedfile/rejected?email=';
  private uploadUrl =  '/v1/uploadedfile/upload?email=';
  private usersListUrl = '/v1/uploadedfile/list?email=';
  private userDeletedFilesListUrl = '/v1/uploadedfile/inretention?email=';
  private adminDefaultRetentionPeriodUrl = '/v1/policy/set';
  private getRetentionPeriodUrl = '/v1/policy?email=';
  private orgUsersListUrl = '/v1/user/orgusers?email=';
  private individualuserlistUrl = '/v1/user/individualuserlist';
  private verifyusersUrl = '/v1/user/verifyusers';
  private clientsFileCountUrl = '/v1/uploadedfile/userlist?email=';
  private fileDetailsUrl = '/v1/uploadedfile/details?email=';
  private deleteUserFile = '/v1/uploadedfile/delete'
  // private clientDeleteFile = '/clientDeleteFile';
  private viewFileUrl = '/v1/uploadedfile/view';
  private downloadSingleFileUrl = '/v1/uploadedfile/download?key=';
  private downloadCompressedFileUrl = '/v1/uploadedfile/compressed';
  private clientNamesUrl = '/v1/user/orgnames';
  private fileTrailUrl = '/v1/uploadedfile/trail?key=';
  private notificationStatusUrl = '/v1/sharefile/update';
  private userSignupUrl = '/v1/user/signup';
  private forgotPasswordUrl = '/v1/user/forgotpassword';
  private resetPasswordUrl = '/v1/user/resetpassword';
  private changePasswordUrl = '/v1/user/changepassword';
  private activateUserdUrl = '/v1/user/activateuser';
  private userAddUrl = '/v1/user/add';
  private userLoginUrl = '/v1/user/login';
  private clientsSharedFilesUrl = '/v1/sharedfile/org?email=';
  private allSharedFilesUrl = '/v1/sharedfile/allfiles?email=';
  private getUserByIdUrl = '/v1/user?id=';
  private getUserByEmailUrl = '/v1/user/info?email=';
  public _loginStatus: any;

  loginStatus: any;
  currentPath: string;

  constructor(private _http: HttpClient, private router: Router, private cookieService: CookieService) {
  }

  loginUser(loginCredentials: userLogin) {
    this.loginStatus = loginCredentials.email;
    this._loginStatus = loginCredentials;
    return this._http.post(this.AuhtorizationServer + this.userLoginUrl, loginCredentials);
  }

  registerUser(registerCredential: userRegister) {
    return this._http.post(this.AuhtorizationServer + this.userSignupUrl, registerCredential);
  }

  addUserByAdmin(registerCredential: userRegister) {
    return this._http.post(this.AuhtorizationServer + this.userAddUrl, registerCredential);
  }

  forgotPassword(data: any) {
    return this._http.post(this.AuhtorizationServer + this.forgotPasswordUrl, {
      'email': data.email,
    });
  }

  resetPassword(data: any) {
    return this._http.post(this.AuhtorizationServer + this.resetPasswordUrl, data);
  }

  changePassword(data: any) {
    return this._http.post(this.AuhtorizationServer + this.changePasswordUrl, data);
  }

  activateUser(data: any) {
    return this._http.post(this.AuhtorizationServer + this.activateUserdUrl, data);
  }

  getUserById(id: any) {
    return this._http.get(this.AuhtorizationServer + this.getUserByIdUrl + id);
  }

  uploadFile(file: any, data: any) {
    return this._http.post(this.AuhtorizationServer + this.uploadUrl + data, file, { reportProgress: true, observe: 'events' });
  }

  userList(data: any) {
    return this._http.get(this.AuhtorizationServer + this.usersListUrl + data);
  }

  rejectFilesList(data: any) {
    return this._http.get(this.AuhtorizationServer + this.rejectedFilesListUrl + data);
  }

  deletedFilesList(data: any) {
    return this._http.get(this.AuhtorizationServer + this.userDeletedFilesListUrl + data);
  }

  // Admin routes
  clientList(data: any) {
    return this._http.get(this.AuhtorizationServer + this.orgUsersListUrl + data);
  }

  registerUserList() {
    return this._http.get(this.AuhtorizationServer + this.individualuserlistUrl);
  }

 verifyUsers(data: any) {
    return this._http.post(this.AuhtorizationServer + this.verifyusersUrl, data);
  }

  clientSharedFiles(data: any) {
    return this._http.get(this.AuhtorizationServer + this.clientsSharedFilesUrl + data);
  }

  clientSharedFilesAll(data: any) {
    return this._http.get(this.AuhtorizationServer + this.allSharedFilesUrl + data);
  }

  clientFileCount(data: any, clientEmail: any) {
    return this._http.get(this.AuhtorizationServer + this.clientsFileCountUrl + data, {
      params: {
        clientEmail: clientEmail
      }
    });
  }

  userDetail(data: any, title: any) {
    return this._http.post(this.AuhtorizationServer + this.fileDetailsUrl + data, { 'title': title });
  }

  viewFile(email: any, filename: any) {
    return this._http.post(this.AuhtorizationServer + this.viewFileUrl, { 'email': email, 'filename': filename });
  }

  downloadFile(key: any, email: any) {
    return this._http.post(this.AuhtorizationServer + this.downloadSingleFileUrl, {key: key, email: email}, { responseType: 'blob'});
  }

  downloadCompressedFile(files: any) {
    return this._http.post(this.AuhtorizationServer + this.downloadCompressedFileUrl, {files: files }, { responseType: 'blob'});
  }

  getFileTrail(key: any) {
    return this._http.get(this.AuhtorizationServer + this.fileTrailUrl + key);
  }

  // Old implementation for retention
  deleteFiles(data: any, parFileDetails: any, key: any, retentionValue: any, policySet: any) {
    return this._http.post(this.AuhtorizationServer + this.deleteUserFile, {
      'filename': parFileDetails,
      'email': data,
      'key': key,
      'timePeriod': retentionValue,
      'policySet': policySet
    });
  }

  deleteFileGracePeriod(email: any, key: any, parFileDetails: any,) {
    return this._http.post(this.AuhtorizationServer + this.deleteUserFile, {
      'key': key,
      'email': email,
      'filename': parFileDetails
    });
  }

  defaultRetentionPeriod(email: any, retentionPeriod: any) {
    return this._http.post(this.AuhtorizationServer + this.adminDefaultRetentionPeriodUrl, {
      'email': email,
      'timePeriod': retentionPeriod
    });
  }

  getRetentionPeriod(email: any): Observable<any> {
    return this._http.get(this.AuhtorizationServer + this.getRetentionPeriodUrl + email);
  }

  // clientDeleteFiles(email: any, filename: any, useremail: any) {
  //   return this._http.post(this.AuhtorizationServer + this.clientDeleteFile, {
  //     'filename': filename, 'email': useremail, 'useremail': email
  //   });
  // }

  getClientNames(): Observable<any> {
    return this._http.get(this.AuhtorizationServer + this.clientNamesUrl);
  }

  notificationStatus(userEmail: any, key: any, notifyStatus: any): Observable<any> {
    return this._http.put(this.AuhtorizationServer + this.notificationStatusUrl, {
      'email': userEmail,
      'key': key,
      'status': notifyStatus
    });
  }

  loginUserToken() {
    return localStorage.getItem('Auth-Token');
  }

  navBar() {
    return window.location.hash === '#/dashboard' ? true : false;
  }

  roleBased() {
    return localStorage.getItem('ROLE');
  }

  individualEmail() {
    return localStorage.getItem('EMAIL');
  }

  getToken() {
    return localStorage.getItem('Auth-Token');
  }

  logout() {
    this.cookieService.removeAll();
    localStorage.removeItem('Auth-Token');
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getUserByEmail(email): Observable<any> {
    return this._http.get(this.AuhtorizationServer + this.getUserByEmailUrl + email);
  }

}
