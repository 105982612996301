import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private injector: Injector,
        private router: Router,
        public auth: AuthenticationService,
        private snackBar: MatSnackBar
    ) { }

    intercept(req, next) {
        const authService = this.injector.get(AuthenticationService);
        const tokenizedReq = req.clone({
            setHeaders: {
                Authorization: `${authService.getToken()}`
            }
        });
        return next.handle(tokenizedReq).pipe(catchError(err => {
            const error = err.error || err.statusText;
            switch (err.status) {
                case 401:
                    this.auth.logout();
                    if (error.message === 'Unauthorized!') {
                        this.snackBar.open('Session Expired', 'Close');
                        this.router.navigate(['login']);
                    }
                    break;
            }
            return throwError(error);
        }));
    }
}
