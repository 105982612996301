import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as glob from 'src/app/services/global-url.service';
import * as watermark from 'watermarkjs';

@Injectable({
    providedIn: "root"
})

export class ShareFileService {
  private AuhtorizationServer = glob.server;
  private shareUsersListUrl = '/v1/user/listsharewith?email='
  private unShareUsersListUrl = '/v1/sharedfile/userslist';
  // private clientUsersListUrl = '/clientGetUser';
  private sharewithUsersUrl = '/v1/sharedfile/share';
  // private clientSharewithUsersUrl = '/clientShareFile';
  private unShareUserUrl = '/v1/sharedfile/unshare';
  private viewSharedFilesUrl = '/v1/sharedfile/userfiles?email=';
  private listSharedFilesUrl = '/v1/sharedfile/myfiles';
  private listConsultantUrl = '/thirdPartyUserNames?email=';
  // private viewConsultantFilesUrl = '/thirdPartyUserLists';
  private addBlockSharedFileUrl = '/v1/sharedfile/view';
  private downloadableFileUrl = '/v1/sharedfile/downlodable';

  constructor(private _http: HttpClient) {
  }

  shareUserList(userEmail: any, filenames: any, authority: any, key: any) {
    return this._http.get(this.AuhtorizationServer + this.shareUsersListUrl + userEmail, {
      params: {
        filename: filenames,
        authority: authority,
        'key': key
      }
    });
  }

  unShareUserList(userEmail: any, filename: any, key: any) {
    return this._http.get(this.AuhtorizationServer + this.unShareUsersListUrl, {
      params: {
        email: userEmail,
        filename: filename,
        key: key
      }
    });
  }

  // clientShareUserList(userEmailId: any, filename: any, userEmail: any, key: any) {
  //   return this._http.get(this.AuhtorizationServer + this.clientUsersListUrl, {
  //     params: {
  //       clientemail: userEmailId,
  //       email: userEmail,
  //       filename: filename,
  //       key: key
  //     }
  //   });
  // }

  shareWithUsers(userEmail: any, filename: any, shareUserEmail:any, key:any, downloadable: boolean) {
    return this._http.post(this.AuhtorizationServer + this.sharewithUsersUrl, {
      'email': userEmail,
      'filename': filename,
      'useremail': shareUserEmail,
      'key': key,
      'downloadable': downloadable
    });
  }

  watermarkImage(file,userid) {
    return watermark([file])
    .image(watermark.text.center('Generated for userId: (' + userid + ') , Curatus Protected', 'normal normal 900 2vh Roboto', '#000000', 0.5))
  }

  // clientShareWithUsers(userEmailId: any, filename: any, shareUserEmail:any, userEmail: any) {
  //   return this._http.post(this.AuhtorizationServer + this.clientSharewithUsersUrl, {
  //     'clientemail': userEmailId,
  //     'useremail': userEmail,
  //     'filename': filename,
  //     'shareemail': shareUserEmail
  //   });
  // }

  unShareFile(userEmail: any, unShareUserEmail:any, key: any, filename:  any) {
    return this._http.post(this.AuhtorizationServer + this.unShareUserUrl, {
      'email': unShareUserEmail,
      'useremail': userEmail,
      'key': key,
      'filename': filename
    });
  }

  viewSharedFile(userEmail: any) {
    return this._http.get(this.AuhtorizationServer + this.viewSharedFilesUrl + userEmail);
  }

  addBlockSharedFile(userEmail: any, sharedUserEmail:any, filename: any, key: any) {
    return this._http.put(this.AuhtorizationServer + this.addBlockSharedFileUrl, {
      'email': userEmail,
      'sharedemail': sharedUserEmail,
      'filename': filename,
      'key': key
    });
  }

  listConsultant(userEmail: any) {
    return this._http.get(this.AuhtorizationServer + this.listConsultantUrl + userEmail);
  }

  // viewConsultantFiles(consultantEmail: any, sharedEmail: any) {
  //   return this._http.get(this.AuhtorizationServer + this.viewConsultantFilesUrl, {
  //     params: {
  //       'email': consultantEmail,
  //       'useremail': sharedEmail
  //     }
  //   });
  // }

  listUserSharedFiles(userEmail: any) {
    return this._http.get(this.AuhtorizationServer + this.listSharedFilesUrl,{
      params: {
        email: userEmail
      }
    });
  }

  fileDownloadableByKey(key: any, useremail: any, downloadable: any) {
    return this._http.put(this.AuhtorizationServer + this.downloadableFileUrl, {
      'key': key,
      'downloadable': downloadable,
      'useremail' : useremail
    });
  }
}
