import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CookieService } from 'ngx-cookie';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CuratusAuthGuardService } from 'src/app/AuthGuard/curatus-auth.service';
import { ForgotPasswordDialogComponent } from 'src/app/shared/dialogs/forgot-password/forgot-password-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  invalidLogin: boolean;
  emailId: any;
  hide = true;
  forgotPasswordRef: MatDialogRef<any>;

  form: FormGroup = new FormGroup({
    emailLogin: new FormControl('', [Validators.required, Validators.email]),
    passwordLogin: new FormControl('', [Validators.required]),
  });

  constructor(private router: Router,
              private authService: AuthenticationService,
              private cookieService: CookieService,
              public dialog: MatDialog,
              private auth: CuratusAuthGuardService) {
    this.invalidLogin = false;
  }

  ngOnInit() {
    this.auth.checkAuthentication();
  }

  loginUserAuth(value: any) {
    this.emailId = value.emailLogin;
    const loginObj = {
      'email': value.emailLogin,
      'password': value.passwordLogin
    };
    this.authService.loginUser(loginObj).subscribe(loginAuthResponce => {
      if (loginAuthResponce && loginAuthResponce['authority']) {
        //this.cookieService.put('email', this.emailId);
        this.cookieService.put('email',loginAuthResponce['email'] );
        localStorage.setItem('Auth-Token', loginAuthResponce['token']);
        localStorage.setItem('ROLE', loginAuthResponce['authority']);
        localStorage.setItem('EMAIL', loginAuthResponce['email']);
        localStorage.setItem('NAME', loginAuthResponce['name']);
        localStorage.setItem('USERID', loginAuthResponce['id']);
        this.cookieService.put('role', loginAuthResponce['authority']);
        let navRoute = (loginAuthResponce['authority'] === 'Organization Admin') ? '/curatus-mvp-admin' : '/dashboard';
        this.router.navigate([navRoute]);
      } else {
        this.invalidLogin = true;
      }
    }, error => {
      this.invalidLogin = true;
    });
  }

  openForgotPasswordDialog() {
    this.forgotPasswordRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '486px',
      height: 'auto',
      disableClose: false
    });
  }
}
