import { NgModule } from '@angular/core';

import {
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSidenavModule,
  MatRadioModule,
  MatExpansionModule,
  MatBadgeModule,
  MatTabsModule,
  MatTooltipModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatMenuModule,
  MatDividerModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';


const modules = [
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSidenavModule,
  MatRadioModule,
  MatExpansionModule,
  MatBadgeModule,
  MatTabsModule,
  MatTooltipModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatMenuModule,
  MatDividerModule,
  MatSlideToggleModule,
  DragDropModule
];

@NgModule({
  imports: modules,
  exports: modules,
})
export class MaterialModule {}
