import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CuratusAuthGuardService } from './AuthGuard/curatus-auth.service';
import { SocketioService } from './services/socketio.service';
import { LiveNotificationsService } from './services/live-notifications.service';
import { ResizeEvent } from 'angular-resizable-element';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'curatus-app';
  isHome: any;

  constructor(
    private location: Location,
    private auth: CuratusAuthGuardService,
    private webSocket: SocketioService
  ) {

  }

  ngOnInit() {
  }
}
