import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { DeleteFileComponent } from './delete-file.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    exports: [

    ],
    declarations: [
        DeleteFileComponent
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }
    ],
    entryComponents: [
        DeleteFileComponent
    ],
    bootstrap: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class DeleteFileModule { }