import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GracePeriodService} from '../../../services/grace-period.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.scss']
})

export class ForgotPasswordDialogComponent implements OnInit {

    email: any;
    forgotForm: FormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private snackBar: MatSnackBar,
        private authService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit() {
    }

    forgotPasswordSubmit(forgotEmail) {
      this.authService.forgotPassword(forgotEmail).subscribe(result => {
        if (result && result['status'] == 200 ) {
          this.dialogRef.close();
          this.snackBar.open(result['message'], 'Close');
          this.router.navigateByUrl('/login');
        }
      },
      err => {
        const errorMessage = err;
        this.snackBar.open(errorMessage.message, 'Close');
      });
    }
}
