import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { CuratusAuthGuardService } from 'src/app/AuthGuard/curatus-auth.service';


@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor(
    private shared: SharedDataService,
    private router: Router,
    private auth: CuratusAuthGuardService
  ) { }

  ngOnInit() {
    this.shared.showNavBar = false;
    if (!this.auth.isAuthenticated()) {
      this.shared.showNavBar = true;
      this.router.navigateByUrl('/login');
    }
  }

  goToDashboard() {
    this.shared.showNavBar = true;
    if (localStorage.getItem('ROLE') === 'Client') {
      this.router.navigateByUrl('/curatus-mvp-admin');
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

}
