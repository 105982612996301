import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as glob from 'src/app/services/global-url.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class GracePeriodService {

  private AuhtorizationServer = glob.server;
  private getGracePeriodByEmail = '/v1/graceperiod/org/';
  private getAllGracePeriodList = '/v1/graceperiod/list';
  private setGracePeriodByEmail = '/v1/graceperiod/update';

  constructor(private _http: HttpClient) {
  }

  getGracePeriod(userEmail: any) {
    return this._http.get(this.AuhtorizationServer + this.getGracePeriodByEmail + userEmail);
  }

  getGracePeriodList() {
    return this._http.get(this.AuhtorizationServer + this.getAllGracePeriodList);
  }

  setGracePeriod(objGracePeriod: any) {
    return this._http.post(this.AuhtorizationServer + this.setGracePeriodByEmail, objGracePeriod);
  }

}
