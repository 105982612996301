import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CuratusAuthGuardService } from 'src/app/AuthGuard/curatus-auth.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GracePeriodService } from 'src/app/services/grace-period.service';
import { CronJobService } from 'src/app/services/set-cron-job.service';
import * as moment from 'moment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-edit-settings',
  templateUrl: './edit-settings.component.html',
  styleUrls: ['./edit-settings.component.scss']
})
export class EditSettingsComponent implements OnInit {

  userEmailId: any;
  matcher = new MyErrorStateMatcher();
  changePasswordForm: FormGroup;
  hide = true;

  gracePeriodForm: FormGroup = new FormGroup({
    grace_period_type: new FormControl('', [Validators.required]),
    grace_period_value: new FormControl({value: '', disabled: true } , [Validators.required])
  });
  savedHrs: number;
  corporateID: string;
  defualtHrsLabel = 72;
  lastRun = '';
  nextRun = '';

  constructor(
    public gracePeriodService: GracePeriodService,
    private cookieService: CookieService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private curatusAuthService: CuratusAuthGuardService,
    public cronService: CronJobService,
) {
  this.initForm();
}

  ngOnInit() {
    this.userEmailId = this.cookieService.get('email');
    this.changePasswordForm.get('email').setValue(this.userEmailId);
    this.getGracePeriodFromDB();
    this.getDetail();
  }

  roleAdmin() {
    return this.curatusAuthService.isAdmin();
  }

  goToDashboard() {
    if (localStorage.getItem('ROLE') === 'Organization Admin') {
      this.router.navigateByUrl('/curatus-mvp-admin');
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  // Change Password section
  initForm() {
    this.changePasswordForm = this.fb.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{6,}")]),
      confirmPassword: new FormControl('')
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  changePasswordSubmit(passwordObj) {
    this.authService.changePassword(passwordObj).subscribe(result => {
      if (result && result['status'] == 200 ) {
        this.snackBar.open(result['message'], 'Close');
      }
    },
    err => {
      const errorMessage = err.error;
      this.snackBar.open(errorMessage.message, 'Close');
    });
  }

  // Grace period section
  getGracePeriodFromDB() {
    this.gracePeriodService.getGracePeriod(this.userEmailId).subscribe((data) => {
        this.savedHrs = data['gracePeriod'].hours;
        this.corporateID = data['gracePeriod']._id;
        const type_period = data['gracePeriod'].periodType;
        this.gracePeriodForm.get('grace_period_type').setValue(type_period);
        if (type_period === 'CUSTOM') {
          this.gracePeriodForm.get('grace_period_value').enable();
          this.gracePeriodForm.get('grace_period_value').setValue(this.savedHrs);
        }
    });
  }

  setGracePeriod(value) {
    if (value === 'CUSTOM') {
      this.gracePeriodForm.get('grace_period_value').enable();
    } else {
      this.gracePeriodForm.get('grace_period_value').disable();
    }
  }

  gracePeriodSubmit() {
    const gracePeriodObj = {
      gracePeriod: {},
      email: ''
    };
    gracePeriodObj.email = this.userEmailId;
    if (this.gracePeriodForm.value.grace_period_type === 'DEFAULT'){
      this.gracePeriodForm.get('grace_period_value').enable();
      this.gracePeriodForm.get('grace_period_value').setValue(this.defualtHrsLabel);
      gracePeriodObj.gracePeriod['hours'] = this.gracePeriodForm.get('grace_period_value').value;
      gracePeriodObj.gracePeriod['periodType'] = this.gracePeriodForm.get('grace_period_type').value;
      gracePeriodObj.gracePeriod['_id'] = this.corporateID;
    } else {
      gracePeriodObj.gracePeriod['hours'] = this.gracePeriodForm.get('grace_period_value').value;
      gracePeriodObj.gracePeriod['periodType'] = this.gracePeriodForm.get('grace_period_type').value;
      gracePeriodObj.gracePeriod['_id'] = this.corporateID;
    }
    this.gracePeriodService.setGracePeriod(gracePeriodObj).subscribe((result) => {
      if (result) {
        this.snackBar.open('Grace Period has been saved successfully.', 'Close');
      }
    });
  }

  // Cron job section
  getDetail(){
    this.cronService.getCronJobInfo().subscribe((data) => {
      if (data['status'] === 200){
        this.lastRun = moment(data['dates'].lastRun).utc().format('yyyy/MM/DD hh:mm:ss a');
        this.nextRun = moment(data['dates'].nextRun).utc().format('yyyy/MM/DD hh:mm:ss a');
      }
    });
  }

  runCronJobManually(){
    this.cronService.runCronJob().subscribe((data) => {
      if (data['status'] === 200){
        this.snackBar.open(data['message'], 'Close');
      }
    });
  }

}
