import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedFile} from "../../../global";

@Component({
    selector: 'corporate-rejected-files',
    templateUrl: './corporate-rejected-files.component.html',
    styleUrls: ['./corporate-rejected-files.component.scss']
})

export class CorporateRejectedFiles {


  @Input() file: any;
  constructor() {
  }


}
