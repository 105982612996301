import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {ViewFileDialogConfig} from './view-file-dialog-config';
import {NgxExtendedPdfViewerComponent} from 'ngx-extended-pdf-viewer';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'view-file-dialog',
  templateUrl: './view-file-dialog.component.html',
  styleUrls: ['./view-file-dialog.component.scss']
})
export class ViewFileDialogComponent implements OnInit, AfterViewInit {
  viewFile: any;
  viewFileFormat: any;
  configuredViewer: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewFileDialogConfig,
    public dialog: MatDialog,
    public domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    if (this.data.viewFileFormat === true) {
      this.configuredViewer = 'office';
    } else if (this.data.viewFileFormat === false) {
      this.configuredViewer = 'google';
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngAfterViewInit() {

  }
}
