import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedFile} from "../../global";

@Component({
    selector: 'file-notification',
    templateUrl: './file-notification.component.html',
    styleUrls: ['./file-notification.component.scss']
})

export class FileNotificationComponent {
  @Input() file: SharedFile;
  @Output() gracePeriodExpiredEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() acceptFileEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  public handleGracePeriodExpired() {
    this.gracePeriodExpiredEvent.emit(this.file);
  }

  public handleRejectFile() {
    this.rejectFileEvent.emit(this.file);
  }

  public handleAcceptFile() {
    this.acceptFileEvent.emit(this.file);
  }

  public getDate() {
    return Date.now();
  }
}
