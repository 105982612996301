import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as glob from 'src/app/services/global-url.service';

@Injectable({
    providedIn: "root"
})

export class CronJobService {
  private AuhtorizationServer = glob.server;
  private getCronJobInfoUrl = '/v1/task/purgefile/date';
  private runCronJobUrl = '/v1/task/purgefile/run';


  constructor(private _http: HttpClient) {
  }

  getCronJobInfo() {
    return this._http.get(this.AuhtorizationServer + this.getCronJobInfoUrl);
  }

  runCronJob() {
    return this._http.post(this.AuhtorizationServer + this.runCronJobUrl, {});
  }
}
