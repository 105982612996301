import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedFile} from "../../global";

@Component({
    selector: 'search-swimlanes',
    templateUrl: './search-swimlanes.component.html',
    styleUrls: ['./search-swimlanes.component.scss']
})

export class SearchSwimlanesComponent {

  @Input() searchTerm: any;
  @Output() searchTermEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() placeholder;

  constructor() {
  }

  public handleSearchTerm() {
    this.searchTermEvent.emit(this.searchTerm);
  }
}
