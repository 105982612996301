import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {CuratusAuthGuardService} from 'src/app/AuthGuard/curatus-auth.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {SetRetentionComponent} from 'src/app/shared/dialogs/set-retention/set-retention.component';
import {SharedDataService} from 'src/app/services/shared-data.service';
import {LogoutDialogComponent} from 'src/app/shared/dialogs/logout-dialog/logout-dialog.component';
import {ShareFileService} from '../../../services/share-file.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-curatus-navbar',
    templateUrl: './curatus-navbar.component.html',
    styleUrls: ['./curatus-navbar.component.scss']
})

export class CuratusNavbarComponent implements OnInit {
    userEmail: any;
    role: boolean;
    navBar: boolean;
    emailValue: any;
    listOfFiles: any;
    listOfFilesCount: any;
    deleteUserRef: MatDialogRef<any>;
    shareFilesRef: MatDialogRef<any>;
    setRetentionRef: MatDialogRef<any>;
    setGracePeriodRef: MatDialogRef<any>;
    setCronJobdRef: MatDialogRef<any>;
    myProfileRef: MatDialogRef<any>;
    logoutConfirmationRef: MatDialogRef<any>;
    userName: String;

    constructor(
        private cookieService: CookieService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<any>,
        private curatusAuthService: CuratusAuthGuardService,
        private authService: AuthenticationService,
        private shareFilesService: ShareFileService,
        private sharedDataService: SharedDataService,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.emailValue = this.cookieService.get('email');
        this.userName = localStorage.getItem('NAME');
    }

    isAuthenticated() {
        return this.curatusAuthService.isAuthenticated();
    }

    roleAdmin() {
        return this.curatusAuthService.isAdmin();
    }

    roleSuperAdmin() {
        return this.curatusAuthService.isSuperAdmin();
    }

    userEmailId() {
        return this.curatusAuthService.adminEmail();
    }

    openSetRetentionDialog(filename: any, dataValue: any, index: any, key: any) {
        this.setRetentionRef = this.dialog.open(SetRetentionComponent, {
          width: '600px',
          height: '280px',
          disableClose: false
        });
        this.setRetentionRef.componentInstance.filename = filename;
        this.setRetentionRef.componentInstance.key = key;
        this.setRetentionRef.afterClosed().subscribe(result => {
          console.log(result);
        });
    }

    openMySettings() {
      this.router.navigateByUrl('/edit-settings');
    }

    goToRegisterUsersList(){
        this.router.navigateByUrl('/curatus-mvp-admin/register-user-list');
    }

    goToUsersList() {
      this.router.navigateByUrl('/curatus-mvp-admin/user-list');
    }

    logOut() {
        this.logoutConfirmationRef = this.dialog.open(LogoutDialogComponent, {
            width: '400px',
            height: '175px',
            disableClose: true
        });
        this.logoutConfirmationRef.componentInstance.confirmMessage = 'Are you sure you want to logout?';
        this.logoutConfirmationRef.componentInstance.button = {
            confirm: true,
            confirmText: 'Yes',
            cancel: true
        };
    }
}
