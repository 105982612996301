import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedCommonModule } from 'src/app/shared/shared.module';
import { DatePipe } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CuratusAdminComponent } from './curatus-admin.component';

@NgModule({
    imports: [
        BrowserModule,
        SharedCommonModule,
        MaterialModule,
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        CuratusAdminComponent
    ],
    providers: [
        DatePipe
    ],
    entryComponents: [

    ],
    bootstrap: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class CuratusAdminModule {

}
