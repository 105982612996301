import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CookieService} from 'ngx-cookie';
import {MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {DeleteFileComponent} from 'src/app/shared/dialogs/delete-file/delete-file.component';
import {ShareFileDialogComponent} from '../../shared/dialogs/share-file-dialog/share-file-dialog.component';
import {ShareFileService} from '../../services/share-file.service';
import {ViewFileDialogComponent} from '../../shared/dialogs/view-file-dialog/view-file-dialog.component';

@Component({
  selector: 'app-curatus-admin',
  templateUrl: './curatus-admin.component.html',
  styleUrls: ['./curatus-admin.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CuratusAdminComponent implements OnInit {

  displayedColumns: string[] = ['firstName', 'lastName', 'userEmail', 'createdDate', 'symbol'];
  displayedSharedColumns: string[] = ['filename', 'date', 'sharedBy', 'action'];
  displayedSmColumns: string[] = ['filename', 'date', 'action'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  listUser: MatTableDataSource<any>;
  listFiles: MatTableDataSource<any>;
  noData: boolean;
  emailValue: any;
  clientData: any;
  columns: any[];
  parFileDetails: any;
  fileLists: any;
  loadingFiles: boolean;
  deletionIndex: any;
  sharedFiles: MatTableDataSource<any>;
  expandFiles: boolean;
  viewFilesRef: MatDialogRef<any>;
  deleteUserRef: MatDialogRef<any>;
  shareFilesRef: MatDialogRef<any>;
  unShareFilesRef: MatDialogRef<any>;

  constructor(
    private authService: AuthenticationService,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private shareFilesService: ShareFileService
  ) { }

  ngOnInit() {
    this.emailValue = this.cookieService.get('email');
    this.authService.clientList(this.emailValue).subscribe(list => {
      this.clientData = list['data'];
      if (this.clientData) {
        this.columns = this.clientData;
        this.listUser = new MatTableDataSource(this.clientData);
        this.listUser.sort = this.sort;
        this.listUser.paginator = this.paginator;
      }
    });

    this.shareFilesService.listUserSharedFiles(this.emailValue).subscribe(result => {
      if (result) {
        const sharedFilesArr = result['payload'];
        this.sharedFiles = new MatTableDataSource(sharedFilesArr);
      }
    });
  }

  onClick(userEmail: any) {
    this.loadingFiles = true;
    this.noData = false;
    this.authService.clientFileCount(userEmail, this.emailValue).subscribe(result => {
      if (result && result['link'].length !== 0) {
        this.noData = false;
        this.fileLists = result['link'];
        this.listFiles = new MatTableDataSource(this.fileLists);
        this.expandFiles = true;
        this.loadingFiles = false;
      } else if (result && result['link'].length === 0) {
        this.expandFiles = false;
        this.loadingFiles = false;
        this.noData = true;
      }
    });
  }

  // TODO: WHERE IS DOC AND VIDEO HANDLING HERE?
  viewFile(filelink: any, extension: any) {
    if (extension === '.pdf') {
      this.viewFilesRef = this.dialog.open(ViewFileDialogComponent, {
        width: '1000px',
        height: '600px',
        disableClose: false,
        data: {
          viewType: 'pdf',
          viewFile: filelink
        }
      });
    } else if (extension === '.png' || '.jpg') {
      this.viewFilesRef = this.dialog.open(ViewFileDialogComponent, {
        disableClose: false,
        data: {
          viewerType: 'image',
          viewFile: filelink,
          viewImageFormat: true
        }
      });
    }
  }

  deleteFile(filename:any, email:any, index: any) {
    this.deleteUserRef = this.dialog.open(DeleteFileComponent, {
        width: '400px',
        height: '175px',
        disableClose: true
    });
    this.deleteUserRef.componentInstance.email = email;
    this.deleteUserRef.componentInstance.filename = filename;
    this.deleteUserRef.componentInstance.fileDeleteAction = 'Client File Deletion';
    this.deleteUserRef.afterClosed().subscribe(result => {
      if (result === 'Yes') {
        const data = this.listFiles.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + this.deletionIndex, 1);

        this.listFiles.data = data;
      }
    });
  }

  shareFile(filename: any, useremail: any, key: any) {
    this.shareFilesRef = this.dialog.open(ShareFileDialogComponent, {
        width: '600px',
        height: '470px',
        disableClose: true
    });
    this.shareFilesRef.componentInstance.filename = filename;
    this.shareFilesRef.componentInstance.userEmail = useremail;
    this.shareFilesRef.componentInstance.activeShareDialog = 'Active Share Dialog';
    this.shareFilesRef.componentInstance.key = key;
    this.shareFilesRef.afterClosed().subscribe(result => {
        if (result !== true) {
          this.shareFilesService.listUserSharedFiles(this.emailValue).subscribe(result => {
            if (result) {
              const sharedFilesArr = result['payload'];
              this.sharedFiles = new MatTableDataSource(sharedFilesArr);
            }
          });
        }
    });
  }

  unShareFile(userEmail: any, emailId: any, filename: any, index: number, key: any) {
    this.unShareFilesRef = this.dialog.open(ShareFileDialogComponent, {
        width: '400px',
        height: '250px',
        disableClose: true
    });
    this.unShareFilesRef.componentInstance.userEmail = userEmail;
    this.unShareFilesRef.componentInstance.emailId = emailId;
    this.unShareFilesRef.componentInstance.fileNames = filename;
    this.unShareFilesRef.componentInstance.key = key;
    this.unShareFilesRef.componentInstance.activeShareDialog = 'Active UnShare Dialog';
    this.unShareFilesRef.afterClosed().subscribe(result => {
        if (result['message'] === 'file unshared') {
          const data = this.sharedFiles.data;
          data.splice((this.paginator.pageIndex * this.paginator.pageSize) + this.deletionIndex, 1);
          this.sharedFiles.data = data;
          this.snackBar.open('File has been unshared successfully.', 'Close');
        }
    });
  }

  onTabClick(event: any) {
    if (event.tab.textLabel === 'Individuals') {
      this.authService.clientList(this.emailValue).subscribe(list => {
        this.clientData = list['data'];
        if (this.clientData) {
          this.columns = this.clientData;
          this.listUser = new MatTableDataSource(this.clientData);
          this.listUser.sort = this.sort;
          this.listUser.paginator = this.paginator;
        }
      });
    }
  }
}
