import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedFile} from "../../global";

@Component({
    selector: 'shared-file',
    templateUrl: './shared-file.component.html',
    styleUrls: ['./shared-file.component.scss']
})

export class SharedFileComponent {
  @Input() file: SharedFile;
  @Output() gracePeriodExpiredEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() displayDetailsEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() unshareFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateShareFileEvent: EventEmitter<any> = new EventEmitter<any>();

  checked: boolean;

  constructor() {
  }

  public handleGracePeriodExpired() {
    this.gracePeriodExpiredEvent.emit(this.file);
  }

  sliderchanged() {
    
  }

  public handleDisplayDetails() {
    this.displayDetailsEvent.emit('hello');
  }

  public handleUnshareFile() {
    this.unshareFileEvent.emit(this.file);
  }

  public handleUpdateSharedFile() {
    this.updateShareFileEvent.emit(this.file);
  }

  public getDate() {
    return Date.now();
  }
}
