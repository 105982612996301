import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './Screens/authentication/register/register.component';
import { LoginComponent } from './Screens/authentication/login/login.component';
import { AdvancedUsersComponent } from './Screens/authentication/advanced-users/advanced-users.component';
import { NotfoundComponent } from './Screens/layouts/notfound/notfound.component';
import { DashboardComponent } from './Screens/dashboard/dashboard.component';
import { CuratusAdminComponent } from './Screens/curatus-admin/curatus-admin.component';
import { CuratusMvpAdminComponent } from './Screens/curatus-mvp-admin/curatus-mvp-admin.component';
import { CuratusAuthGuardService } from './AuthGuard/curatus-auth.service';
import { UserListAdminComponent } from './Screens/user-list-admin/user-list-admin.component';
import { RegisterUserListAdminComponent } from './Screens/register-user-list-admin/register-user-list-admin.component';
import { EditShareDetailComponent } from './Screens/edit-share-detail/edit-share-detail.component';
import { ResetPasswordComponent } from './Screens/authentication/reset-password/reset-password.component';
import { EditSettingsComponent } from './Screens/edit-settings/edit-settings.component';
import { MainComponent } from './Screens/layouts/main/main.component';
import { RoleGuard } from './AuthGuard/role-guard';
import { ViewUserFilesComponent } from './Screens/view-user-files/view-user-files.component';

export enum Role {
  OrganizationAdmin = 'Organization Admin',
  OrganizationUser = 'Organization User',
  OrganizationClient = 'Organization Client',
  Individual = 'Individual',
  SuperAdmin= 'Super Admin'
}

const routes: Routes = [
  // Auth routing
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'advanced-users',
    component: AdvancedUsersComponent
  },
  {
    path: 'reset',
    component: ResetPasswordComponent
  },
  // App routing
  {
    path: '',
    component: MainComponent,
    canActivate: [CuratusAuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [CuratusAuthGuardService]
      },
      {
        path: 'edit-share-detail/:id',
        component: EditShareDetailComponent,
        canActivate: [CuratusAuthGuardService]
      },
      {
        path: 'edit-settings',
        component: EditSettingsComponent,
        canActivate: [CuratusAuthGuardService]
      },
      {
        path: 'admin',
        component: CuratusAdminComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.OrganizationAdmin] }
      },
      {
        path: 'curatus-mvp-admin',
        component: CuratusMvpAdminComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.OrganizationAdmin] }
      },
      {
        path: 'curatus-mvp-admin/user-list',
        component: UserListAdminComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.OrganizationAdmin] }
      },
      {
        path: 'curatus-mvp-admin/register-user-list',
        component: RegisterUserListAdminComponent,
      //   canActivate: [RoleGuard],
      //  data: { roles: [Role.SuperAdmin] }
      },
      {
        path: 'curatus-mvp-admin/view-user-files/:id',
        component: ViewUserFilesComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.OrganizationAdmin] }
      },
    ]
  },
  {
    path: 'not-found',
    component: NotfoundComponent
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
