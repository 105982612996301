import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedFile} from "../../global";

@Component({
    selector: 'inbox-file',
    templateUrl: './inbox-file.component.html',
    styleUrls: ['./inbox-file.component.scss']
})

export class InboxFileComponent {
  @Input() file: SharedFile;
  @Output() gracePeriodExpiredEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadFileEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  public handleGracePeriodExpired() {
    this.gracePeriodExpiredEvent.emit(this.file);
  }

  handleViewClick() {
    this.viewFileEvent.emit(this.file);
  }

  handleDownloadClick() {
    this.downloadFileEvent.emit(this.file);
  }

  public getDate() {
    return Date.now();
  }
}
