import {Component} from '@angular/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CookieService} from 'ngx-cookie';
import {LiveNotificationsService} from 'src/app/services/live-notifications.service';
import {ShareFileService} from '../../../services/share-file.service';

@Component({
    selector: 'app-unshare-dialog',
    templateUrl: './unshare-dialog.component.html',
    styleUrls: ['../delete-file/delete-file.component.css']
})
export class UnshareDialogComponent {
    public userEmailId: any;
    public sender: any;
    public receiver: any;
    public key: any;
    public filename: any;
    public confirmMessage: string;
    public title: string;
    public button: ButtonModel;
    constructor(
        public dialogRef: MatDialogRef<UnshareDialogComponent>,
        private authService: AuthenticationService,
        private shareFilesService: ShareFileService,
        private cookieService: CookieService,
        private liveNotificationService: LiveNotificationsService,
        private snackBar: MatSnackBar
        ) {
        this.button = {
            confirm: true,
            confirmText: 'Yes'
        };
    }

    onConfirm() {
      var arrUser = [];
      arrUser.push(this.receiver);
      this.shareFilesService.unShareFile(arrUser, this.sender, this.key, this.filename).subscribe(result => {
        if (result) {
            this.liveNotificationService.sendNotification({
              'email': this.userEmailId,
              'filename': this.filename,
              'key': this.key
            });
            this.snackBar.open('File has been unshared successfully.', 'Close');
            this.dialogRef.close('closed');
        }
      });
    }

    ngOnInit() {
        this.userEmailId = this.cookieService.get('email');
    }
}

export class ButtonModel {
    confirm?: boolean;
    cancel?: boolean;
    close?: boolean;
    confirmText?: string;
}
