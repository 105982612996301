import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UploadFileDialogComponent } from './upload-file-dialog.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';

@NgModule({
    imports: [
        BrowserModule,
        MaterialModule,
        FormsModule
    ],
    exports: [

    ],
    declarations: [
        UploadFileDialogComponent
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }
    ],
    entryComponents: [
        UploadFileDialogComponent
    ],
    bootstrap: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class UploadFileDialogModule { }
