import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private _uploadDataSource =  new Subject<any>();
  uploadData$ = this._uploadDataSource.asObservable();

  private _deletedAdminDataSource =  new Subject<any>();
  adminDeletedData$ = this._deletedAdminDataSource.asObservable();

  private _deletedUserDataSource =  new Subject<any>();
  userDeletedData$ = this._deletedAdminDataSource.asObservable();

  public storage: any;
  public storageFiles: any;
  public user: any;

  showNavBar = true;
  constructor() { }

  sendData(data: any) {
    this._uploadDataSource.next(data);
  }

  sendAdminDeletedData(data: any) {
    this._deletedAdminDataSource.next(data);
  }

  sendUserDeletedData(data: any) {
    this._deletedUserDataSource.next(data);
  }

  showOrHideSideNav() {
    return this.showNavBar;
  }

}
