import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  public socketStatus = false;
  public user: any;

  constructor(private socket: Socket) {
    this.checkStatus();
  }

  checkStatus() {
    this.socket.on('connect', () => {
      console.log("Connected to the server");
      this.socketStatus = true;
    });

    this.socket.on('disconnect', () => {
      console.log("Disconnected to the server");
      this.socketStatus = false;
    });
  }

  emit(event: string, payload?: any, callback?: Function) {
    console.log('Emit', event);
    this.socket.emit( event, payload, callback);
  }

  listen( event: string ) {
    return this.socket.fromEvent(event);
  }

  loginWS(email: string) {
    console.log('setting user', email);
    this.emit('config-user', { email } );
  }

}
