import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-register-user-list-admin',
  templateUrl: './register-user-list-admin.component.html',
  styleUrls: ['./register-user-list-admin.component.scss']
})
export class RegisterUserListAdminComponent implements OnInit {

  displayedColumns: string[] = ['email', 'first_name', 'date', 'category', 'action'];
  dataSource = [];

  emailValue: any;
  corporateUsers: any;
  userAccessList: any = [];
  addNewUserRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.emailValue = this.cookieService.get('email');
    this.getListOfUsers();
  }

  getListOfUsers() {
    this.userAccessList =[];
    this.loaderService.show();
    this.authService.registerUserList().subscribe(list => {
      var newlist = list['data'].filter(x=>x.email != 'admin@curatus.io')
      this.corporateUsers = newlist;
      this.dataSource = newlist;
      this.loaderService.hide();
    });
  }

  goToDashboard() {
    this.userAccessList = [];
    this.router.navigateByUrl('/curatus-mvp-admin');
  }

  toggleUsers(obj, event) {
    var existItem = this.userAccessList.find(x => x.userId === obj._id);
    if (existItem) {
      var objIndex = this.userAccessList.findIndex(x => x.userId === obj._id);
      this.userAccessList[objIndex].confirmed = event.checked;
    }
    else {
      var name = obj.first_name.split(' ');
      var firstname= name.length>0?name[0]: obj.first_name;
      this.userAccessList.push({ "userId": obj._id, "confirmed": event.checked,"firstname":firstname,"email":obj.email });
    }
  }

  submitVerifyUserList() {
    if (this.userAccessList.length > 0) {
      this.loaderService.show();
      var requestVerifyUser = { "userAccessList": this.userAccessList };
      this.authService.verifyUsers(requestVerifyUser).subscribe(list => {
        if (list) {
          this.snackBar.open('User Activated successfully.', 'Close');
          this.getListOfUsers();
          this.loaderService.hide();
        }
      });
    }
  }
}
