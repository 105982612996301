import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {CookieService} from 'ngx-cookie';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {SharedDataService} from 'src/app/services/shared-data.service';
import {SetRetentionComponent} from 'src/app/shared/dialogs/set-retention/set-retention.component';
import {DeleteFileGracePeriodComponent} from 'src/app/shared/dialogs/delete-file-grace-period/delete-file-grace-period.component';
import * as moment from 'moment';
import {UploadFileDialogComponent} from '../../../shared/dialogs/upload-file-dialog/upload-file-dialog.component';
import {UploadedFile} from '../../../global';
import {ShareFileService} from '../../../services/share-file.service';
import {ShareFileDialogComponent} from '../../../shared/dialogs/share-file-dialog/share-file-dialog.component';
import {DownloadDialogComponent} from '../../../shared/dialogs/download-dialog/download-dialog.component';

import { saveAs } from 'file-saver';
import { LiveNotificationsService } from 'src/app/services/live-notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  deleteUserRef: MatDialogRef<any>;
  shareFilesRef: MatDialogRef<any>;
  downloadConfirmationRef: MatDialogRef<any>;
  userUploadRef: MatDialogRef<UploadFileDialogComponent>;
  setRetentionRef: MatDialogRef<SetRetentionComponent>;
  emailValue: any;
  listOfFiles: any;
  listOfFilesCount: any;
  sharedFiles: any;
  userDeletedFiles: any;
  showGracePeriod = false;
  searchTermUpload: any;
  listSelectedFiles = [];
  subscriptionObject: Subscription;

 /* configSidebar: CountdownConfig = {
    leftTime: 60,
    format: 'HH:mm:ss',
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);
      return CountdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
            return v.toString().padStart(match.length, '0');
          });
        }
        return current;
      }, formatStr);
    },
  }*/

  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    private shareFilesService: ShareFileService,
    private dataService: SharedDataService,
    private snackBar: MatSnackBar,
    private liveNotificationsService: LiveNotificationsService
  ) {
  }

  ngOnInit() {
    this.getRealNotifications();
    this.emailValue = this.cookieService.get('email');
    this.getUsersFiles();

    // this.dataService.uploadData$.subscribe(result => {
    //   if (result) {
    //     this.authService.userList(this.emailValue).subscribe(list => {
    //       this.listOfFiles = list['payload'];
    //       this.listOfFilesCount = this.listOfFiles.length;
    //     });
    //   }
    // });
  }

  getRealNotifications() {
    this.subscriptionObject = this.liveNotificationsService
      .getNotifications()
      .subscribe((data: any) => {
        this.getUsersFiles();
      });
  }

  getUsersFiles() {
    this.authService.userList(this.emailValue).subscribe(list => {
      this.listOfFiles = list['payload'];
      this.listOfFilesCount = this.listOfFiles.length;
      this.listOfFiles.forEach(item => {
        item.showGracePeriod = false;
        item.disabledButton = false;
        if (item.deletedAt) {
          let time = moment(item.expiresAt).diff( moment().utc().format(), 'seconds');
          item.showGracePeriod = true;
          item.disabledButton = true;
          item.timeLeftSec = time;
        }
      });
    });
  }

  uploadFile(event: any) {
    this.userUploadRef = this.dialog.open(UploadFileDialogComponent, {
      width: '410px',
      height: 'auto',
      disableClose: false
    });
    this.userUploadRef.componentInstance.userEmailId = this.emailValue;
    this.userUploadRef.afterClosed().subscribe(result => {
      if (result !== true && result !== undefined) {
        this.listOfFiles.push(result);
      }
    });
  }

  // Old implementation about retention - We leave this just in case that we needed
  // deleteFile(filename: any, dataValue: any, index: any, key: any) {
  //   this.authService.getRetentionPeriod(this.emailValue).subscribe(result => {
  //     console.log(result);
  //     if (result.policy.status === 200) {
  //       this.deleteUserRef = this.dialog.open(DeleteFileComponent, {
  //         width: '600px',
  //         height: '275px',
  //         disableClose: true
  //       });
  //       this.deleteUserRef.componentInstance.filename = filename;
  //       this.deleteUserRef.componentInstance.key = key
  //       this.deleteUserRef.componentInstance.fileDeleteAction = this.cookieService.get('role');
  //       this.deleteUserRef.afterClosed().subscribe(result => {
  //         console.log(result);
  //         if (result.action === 'Submit' && result.policySet === true) {
  //           this.listOfFiles.splice(index, 1);
  //           this.authService.deletedFilesList(this.emailValue).subscribe(list => {
  //             console.log(list);
  //             this.userDeletedFiles = list['payload'];
  //             if (this.emailValue === 'Client') {
  //               this.dataService.sendAdminDeletedData(this.userDeletedFiles);
  //             } else if(this.emailValue === 'User' || 'thirdParty') {
  //               this.dataService.sendUserDeletedData(this.userDeletedFiles);
  //             }
  //           });
  //         } else if (result.action === 'Submit' && result.policySet === false) {
  //           this.listOfFiles.splice(index, 1);
  //         }
  //       });
  //     } else if (result.policy.status === 400) {
  //       this.setRetentionRef = this.dialog.open(SetRetentionComponent, {
  //         width: '600px',
  //         height: '275px',
  //         disableClose: false
  //       });
  //       this.setRetentionRef.componentInstance.filename = filename;
  //       this.setRetentionRef.componentInstance.key = key;
  //       this.setRetentionRef.afterClosed().subscribe(result => {
  //         console.log(result);
  //       });
  //     }
  //   });
  // }

  deleteFileGracePeriod(file: UploadedFile) {
    const filename = file.filename,
      key = file.key;
    this.deleteUserRef = this.dialog.open(DeleteFileGracePeriodComponent, {
      width: '400px',
      height: '175px',
      disableClose: true
    });
    this.deleteUserRef.componentInstance.confirmMessage = 'Are you sure want to remove this file?';
    this.deleteUserRef.componentInstance.button = {
        confirm: true,
        confirmText: 'Yes',
        cancel: true
    };
    this.deleteUserRef.componentInstance.userEmailId = this.emailValue;
    this.deleteUserRef.componentInstance.filename = filename;
    this.deleteUserRef.componentInstance.key = key;
    this.deleteUserRef.afterClosed().subscribe(result => {
      this.getUsersFiles();
    });
  }

  downloadFile(file: UploadedFile) {
    console.log('call to download file:' + file.key);
    this.authService.downloadFile(file.key,this.emailValue).subscribe(result => {
      var blob = new Blob([result])
      saveAs(blob, file.filename);
    }, err => {
      this.snackBar.open('Failed to download a file ', 'Close')
    });
  }

  shareFile(file: UploadedFile) {
    const filename = file.filename,
      email = file.email,
      key = file.key;
    const emailId = email;
    this.shareFilesRef = this.dialog.open(ShareFileDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true
    });
    this.shareFilesRef.componentInstance.filename = filename;
    this.shareFilesRef.componentInstance.key = key;
    this.shareFilesRef.componentInstance.activeShareDialog = 'Active Share Dialog';
    this.shareFilesRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.dataService.sendData(this.sharedFiles);
 // Comment code for duplicate api Called-- 11-07-2022
        // this.shareFilesService.listUserSharedFiles(this.emailValue).subscribe(data => {
        //   if (data) {            
        //     this.sharedFiles = data['payload'];
        //     this.dataService.sendData(this.sharedFiles);
        //   }
        // });
      }
    });
  }

  setValueToSearchInUpload(term) {
    this.searchTermUpload = term;
  }

  addToListFilesToDownload(file) {
    if(file.isChecked){
      this.listSelectedFiles.push(file);
    } else {
      this.listSelectedFiles = this.listSelectedFiles.filter(x => x._id !== file._id);
    }
    console.log(this.listSelectedFiles);
  }

  toggleAllFiles(event: any) {
    this.listOfFiles.forEach((item) => {
        item.isChecked = event.checked;
        console.log(item);
        if (item.isChecked) {
          this.listSelectedFiles.push(item);
        } else {
          this.listSelectedFiles = this.listSelectedFiles.filter(x => x._id !== item._id);
        }
    });
  }

  downloadAllFiles() {
    let arrFiles = [];
    this.listSelectedFiles.forEach(selectedFile => {
      if(!selectedFile.deletedAt) {
        arrFiles.push({key: selectedFile.key, filename: selectedFile.filename});
      }
    });

    let objDownloadFiles = {
      email: this.emailValue,
      listFiles: arrFiles
    }

    this.downloadConfirmationRef = this.dialog.open(DownloadDialogComponent, {
      width: '420px',
      height: '180px',
      disableClose: true
    });
    this.downloadConfirmationRef.componentInstance.files = objDownloadFiles.listFiles
    this.downloadConfirmationRef.componentInstance.confirmMessage = 'Do you want to download the files as a compressed file? Click Yes for a single compressed file download, and click No for mutiple files download.';
    this.downloadConfirmationRef.componentInstance.button = {
        confirm: true,
        confirmText: 'Yes',
        cancel: true,
        close: true
    };
    this.downloadConfirmationRef.afterClosed().subscribe(data => {
      if(data === 'no') {
        objDownloadFiles.listFiles.forEach(file => {
          this.authService.downloadFile(file.key,this.emailValue).subscribe(result => {
            var blob = new Blob([result])
            saveAs(blob, file.filename);
          }, err => {
            this.snackBar.open('Failed to download the file: '+ file.filename, 'Close')
          });
        })
      }
    })
  }

  ngOnDestroy() {
    this.subscriptionObject.unsubscribe();
  }
}
