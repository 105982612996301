import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CookieService} from 'ngx-cookie';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {DeleteFileComponent} from 'src/app/shared/dialogs/delete-file/delete-file.component';
import {SharedDataService} from 'src/app/services/shared-data.service';
import {ConfirmDialogComponent} from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {UploadFileDialogComponent} from '../../shared/dialogs/upload-file-dialog/upload-file-dialog.component';
import {ShareFileService} from '../../services/share-file.service';
import {ShareFileDialogComponent} from '../../shared/dialogs/share-file-dialog/share-file-dialog.component';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LiveNotificationsService } from 'src/app/services/live-notifications.service';

@Component({
    selector: 'app-dashboard-admin',
    templateUrl: './curatus-mvp-admin.component.html',
    styleUrls: ['./curatus-mvp-admin.component.scss']
})

export class CuratusMvpAdminComponent implements OnInit {

    emailValue: any;
    rejectedFiles: any[] = [];
    corporateUsers: any[] = [];
    deletedFiles: any[] = [];
    allFilesShared: any[] = [];
    allFilesUploaded: any[] = [];
    rejectFileRef: MatDialogRef<any>;
    fileLists: any[] = [];
    searchTerm: any;

    searchTermUser: any;
    searchTermDeleted: any;
    searchTermRejected: any;
    subscriptionObject: Subscription;

    constructor(
        private authService: AuthenticationService,
        private cookieService: CookieService,
        public dialog: MatDialog,
        private shareFilesService: ShareFileService,
        private router: Router,
        private dataShared: SharedDataService,
        public liveNotificationService: LiveNotificationsService,
    ) {

    }

    ngOnInit() {
        this.getRealNotifications();
        this.emailValue = this.cookieService.get('email');
        this.buildDeletedFiles();
        this.buildRejectedFiles();
    }

    getRealNotifications() {
      this.subscriptionObject = this.liveNotificationService
        .getNotifications()
        .subscribe((data: any) => {
          this.buildDeletedFiles();
          this.buildRejectedFiles();
        });
    }

    buildDeletedFiles(){
      this.allFilesUploaded = [];
      this.corporateUsers = [];
      this.deletedFiles = [];
      this.authService.clientList(this.emailValue).subscribe(list => {
        this.corporateUsers = list['data'];
        this.corporateUsers.forEach((userObj, index) => {
          this.allFilesUploaded.push(...userObj['files'])
        });
        this.allFilesUploaded.forEach(fileObj => {
          if(fileObj['deletedAt']){
            fileObj['deletedAt'] = moment(fileObj['deletedAt']).utc().format('MM/DD/YYYY');
            let time = moment(fileObj['expiresAt']).diff(moment().utc().format(), 'minutes');
            if(time <= 0){
              fileObj.hasExpired = true;
            }
            this.deletedFiles.push(fileObj);
          }
        });
      });
    }

    buildRejectedFiles() {
      this.allFilesShared = [];
      this.rejectedFiles = [];
      this.authService.clientSharedFiles(this.emailValue).subscribe(result => {
        if(result['data']){
          this.allFilesShared = result['data'];
          this.allFilesShared.forEach(fileObj => {
            if(fileObj.AR === 'rejected'){
              this.rejectedFiles.push(fileObj);
            }
          });
        }
      });
    }

    viewUploadedFiles(user: any, files: any) {
      this.router.navigate(['/curatus-mvp-admin/view-user-files', user._id]);
    }

    setValueToSearchInUser(term) {
      this.searchTermUser = term;
    }

    setValueToSearchInDeleted(term) {
      this.searchTermDeleted = term;
    }

    setValueToSearchInRejected(term) {
      this.searchTermRejected = term;
    }

    ngOnDestroy() {
      this.subscriptionObject.unsubscribe();
    }

}
