import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SharedFile, UploadedFile} from "../../global";

@Component({
    selector: 'uploaded-file',
    templateUrl: './uploaded-file.component.html',
    styleUrls: ['./uploaded-file.component.scss']
})

export class UploadedFileComponent {
  @Input() file: UploadedFile;
  @Output() gracePeriodExpiredEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() shareFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkBoxFileEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  public handleGracePeriodExpired() {
    this.gracePeriodExpiredEvent.emit(this.file);
  }

  public handleShareClick() {
    this.shareFileEvent.emit(this.file);
  }

  public handleDeleteClick() {
    this.deleteFileEvent.emit(this.file);
  }

  public handleDownloadClick() {
    this.downloadFileEvent.emit(this.file);
  }

  public getDate() {
    return Date.now();
  }

  public toggleFile(file) {
    this.file.isChecked = file.checked;
    this.checkBoxFileEvent.emit(this.file);
  }
}
