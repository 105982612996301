import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatTableDataSource} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {Subscription} from 'rxjs';
import {UploadedFile} from 'src/app/global';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {LiveNotificationsService} from 'src/app/services/live-notifications.service';
import {ShareFileService} from 'src/app/services/share-file.service';
import {DeleteFileGracePeriodComponent} from 'src/app/shared/dialogs/delete-file-grace-period/delete-file-grace-period.component';
import {ShareFileDialogComponent} from 'src/app/shared/dialogs/share-file-dialog/share-file-dialog.component';
import {UnshareDialogComponent} from 'src/app/shared/dialogs/unshare-dialog/unshare-dialog.component';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {ViewFileDialogComponent} from '../../shared/dialogs/view-file-dialog/view-file-dialog.component';
import {ViewFileDialogConfig} from '../../shared/dialogs/view-file-dialog/view-file-dialog-config';

@Component({
  selector: 'app-view-user-files',
  templateUrl: './view-user-files.component.html',
  styleUrls: ['./view-user-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewUserFilesComponent implements OnInit, OnDestroy {

	displayedColumns: string[] = ['fileName', 'email', 'sharedDate', 'action'];
  dataSource = new MatTableDataSource();
  dataSourceReceiver = new MatTableDataSource();
  fileLists = [];
  fileListsReceiver = [];
  corpUserEmail: any;
  corpUserName: any;
  corpUserId: any;
  emailValue: any;
  userId: any;
  fileIndex: any;
  viewFilesRef: MatDialogRef<any>;
  viewImageRef: MatDialogRef<any>;
  viewVideoRef: MatDialogRef<any>;
  shareFilesRef: MatDialogRef<any>;
  deleteUserRef: MatDialogRef<any>;
  unshareUserRef: MatDialogRef<any>;
  auditTrail: any;
  fileFromUserCategory: any;
  subscriptionObject: Subscription;
  private _overlayRef: OverlayRef;
  private _portal: TemplatePortal;
  @ViewChild(TemplateRef, { static: false}) _dialogTemplate: TemplateRef<any>;


  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private shareFilesService: ShareFileService,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public liveNotificationService: LiveNotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) { }

  ngAfterViewInit() {
    this._portal = new TemplatePortal(this._dialogTemplate, this._viewContainerRef);
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });
    this._overlayRef.backdropClick().subscribe(() => this._overlayRef.detach());
  }

  async viewFile(file: any) {
    this._overlayRef.attach(this._portal);
    this.shareFilesService.addBlockSharedFile(this.emailValue, file.email, file.filename, file.key).subscribe(async (result) => {
      const filedata: any = result;
      if (filedata.contentType === 'application/pdf') {
        this.viewFilesRef = this.dialog.open<ViewFileDialogComponent, ViewFileDialogConfig>(ViewFileDialogComponent, {
          width: '52vw',
          height: '95vh',
          disableClose: false,
          panelClass: 'dialog-panel',
          data: {
            viewerType: 'pdf',
            viewFile: filedata.link,
            isDownloadable: file.downloadable
          }
        });
      } else if (filedata.contentType.match(/^image\/*/i)) {
        fetch("data:image/png;base64," + filedata.link)
        .then(res => res.blob())
        .then(async (blob) => {
          let watermarkfile = await this.shareFilesService.watermarkImage(blob,this.userId);
          watermarkfile = watermarkfile.src.replace('data:image/png;base64,','');
          this.viewImageRef = this.dialog.open<ViewFileDialogComponent, ViewFileDialogConfig>(ViewFileDialogComponent, {
            disableClose: false,
            panelClass: 'dialog-panel',
            data: {
              viewerType: 'image',
              viewFile: watermarkfile,
              viewImageFormat: true,
              isDownloadable: file.downloadable
            }
          });
          this.viewImageRef.afterClosed().subscribe(() => this._overlayRef.dispose());
        });
      } else if (filedata.contentType.match(/^video\/*/i)) {
        this.viewVideoRef = this.dialog.open<ViewFileDialogComponent, ViewFileDialogConfig>(ViewFileDialogComponent, {
          width: '52vw',
          height: 'auto',
          disableClose: false,
          panelClass: 'dialog-panel',
          data: {
            viewerType: 'video',
            viewFile: filedata.link,
            contentType: filedata.contentType,
            isDownloadable: filedata.downloadable
          }
        });
      }
    });
  };

  shareFile(filename: any, useremail: any, key: any) {
    this.shareFilesRef = this.dialog.open(ShareFileDialogComponent, {
        width: '600px',
        height: '470px',
        disableClose: true
    });
    this.shareFilesRef.componentInstance.filename = filename;
    this.shareFilesRef.componentInstance.userEmail = useremail;
    this.shareFilesRef.componentInstance.fileUserCategory = this.fileFromUserCategory;
    this.shareFilesRef.componentInstance.activeShareDialog = 'Active Share Dialog';
    this.shareFilesRef.componentInstance.key = key;
    this.shareFilesRef.afterClosed().subscribe(result => {

    });
  }


  // old implementation with policy
  // deleteFile(element: any, filename:any, email:any, key: any, index: any) {
  //   this.fileIndex = index;
  //   this.deleteUserRef = this.dialog.open(DeleteFileComponent, {
  //       width: '600px',
  //       height: '275px',
  //       disableClose: true
  //   });
  //   this.deleteUserRef.componentInstance.email = email;
  //   this.deleteUserRef.componentInstance.filename = filename;
  //   this.deleteUserRef.componentInstance.key = key;
  //   this.deleteUserRef.componentInstance.fileDeleteAction = 'Client File Deletion';
  //   this.deleteUserRef.afterClosed().subscribe(result => {
  //     if (result.action === 'Submit' && result.policySet === true) {
  //       const data = this.dataSource.data;
  //       console.log(data);
  //       data.splice((this.paginator.pageIndex * this.paginator.pageSize) + this.fileIndex, 1);
  //       this.dataSource = new MatTableDataSource(this.dataSource.data);
  //       this.dataSource.paginator = this.paginator;
  //     } else if (result.action === 'Submit' && result.policySet === false) {
  //       const data = this.fileLists.data;
  //       data.splice((this.paginator.pageIndex * this.paginator.pageSize) + this.fileIndex, 1);
  //       this.dataSource = new MatTableDataSource(this.dataSource.data);
  //       this.dataSource.paginator = this.paginator;
  //     }
  //   });
  // }

    deleteFile(file: UploadedFile) {
      var emailToEvaluate = '';
      if(this.corpUserEmail === file['useremail']){
        emailToEvaluate = file['email'];
      } else {
        emailToEvaluate = file['useremail'];
      }
      this.authService.getUserByEmail(emailToEvaluate).subscribe(result => {
        if (result) {
          if(result['data'].category === 'Individual') {
            this.unshareAFileForIndividual(file);
          } else {
            this.deleteFileGracePeriod(file);
          }
        }
      });
    }

    deleteFileGracePeriod(file) {
      const filename = file.filename,
        key = file.key;
      this.deleteUserRef = this.dialog.open(DeleteFileGracePeriodComponent, {
        width: '400px',
        height: '175px',
        disableClose: true
      });
      this.deleteUserRef.componentInstance.confirmMessage = 'Are you sure want to remove this file?';
      this.deleteUserRef.componentInstance.button = {
          confirm: true,
          confirmText: 'Yes',
          cancel: true
      };
      this.deleteUserRef.componentInstance.userEmailId = this.emailValue;
      this.deleteUserRef.componentInstance.filename = filename;
      this.deleteUserRef.componentInstance.key = key;
      this.deleteUserRef.afterClosed().subscribe(result => {
        console.log(result);
        if(result.status && result.status === 405){
          this.snackBar.open(result.message, 'Close');
        } else {
          this.getUserDetail();
        }
      });
    }

    unshareAFileForIndividual(file) {
      const filename = file.filename,
        key = file.key;
      this.unshareUserRef = this.dialog.open(UnshareDialogComponent, {
        width: '400px',
        height: '175px',
        disableClose: true
      });
      this.unshareUserRef.componentInstance.confirmMessage = 'Are you sure want to unshare this file?';
      this.unshareUserRef.componentInstance.button = {
          confirm: true,
          confirmText: 'Yes',
          cancel: true
      };
      this.unshareUserRef.componentInstance.sender = file.email;
      this.unshareUserRef.componentInstance.filename = filename;
      this.unshareUserRef.componentInstance.key = key;
      this.unshareUserRef.componentInstance.receiver = file.useremail;

      this.unshareUserRef.afterClosed().subscribe(result => {
        this.getFilesShared(this.corpUserEmail);
      });
    }

  getFileAuditTrail(key) {
    this.authService.getFileTrail(key).subscribe(result => {
      if (result) {
        this.auditTrail = JSON.parse(result['actions']['data']);
      }
    })
  }

  ngOnInit(): void {
    this.getRealNotifications();
    this.corpUserId = this.route.snapshot.paramMap.get('id');
    this.getUserDetail();
    this.emailValue = this.cookieService.get('email');
    this.userId = localStorage.getItem('USERID');
  }

  getRealNotifications() {
    this.subscriptionObject = this.liveNotificationService
      .getNotifications()
      .subscribe((data: any) => {
        this.getUserDetail();
      });
  }

  goToDashboard() {
    if (localStorage.getItem('ROLE') === 'Organization Admin') {
      this.router.navigateByUrl('/curatus-mvp-admin');
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  getUserDetail() {
    this.authService.getUserById(this.corpUserId).subscribe(result => {
      this.corpUserEmail = result['data'].email;
      this.corpUserName = result['data'].name;
      this.getFilesShared(this.corpUserEmail);
    });
  }

  getFilesShared(emailSelected) {

    this.authService.clientSharedFilesAll(emailSelected).subscribe(result => {
      if(result['data']){
        this.fileListsReceiver = [];
        this.fileLists = [];
        var listOfFiles = result['data'];
        listOfFiles.forEach(file => {
          if(this.corpUserEmail === file.useremail){
            this.fileLists.push(file);
          } else {
            this.fileListsReceiver.push(file);
          }
        });

        this.dataSource = new MatTableDataSource(this.fileLists);
        this.dataSource.paginator = this.paginator;

        this.dataSourceReceiver = new MatTableDataSource(this.fileListsReceiver);
        this.dataSourceReceiver.paginator = this.paginator2;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionObject.unsubscribe();
  }

}
