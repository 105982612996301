import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {CookieService} from 'ngx-cookie';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {DeleteFileComponent} from '../../shared/dialogs/delete-file/delete-file.component';
import {SharedDataService} from '../../services/shared-data.service';
import {ConfirmDialogComponent} from '../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import {LiveNotificationsService} from '../../services/live-notifications.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {SharedFile} from '../../global';
import {UploadFileDialogComponent} from '../../shared/dialogs/upload-file-dialog/upload-file-dialog.component';
import {ShareFileDialogComponent} from '../../shared/dialogs/share-file-dialog/share-file-dialog.component';
import {ShareFileService} from '../../services/share-file.service';
import {Router} from '@angular/router';
import {saveAs} from 'file-saver';
import {ViewFileDialogComponent} from '../../shared/dialogs/view-file-dialog/view-file-dialog.component';
import {ViewFileDialogConfig} from '../../shared/dialogs/view-file-dialog/view-file-dialog-config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

  emailValue: any;
  userId: any;
  fileHistory: any;
  actionButton: boolean;
  deletionButton: boolean;

  userUploadRef: MatDialogRef<any>;
  shareFilesRef: MatDialogRef<any>;
  deleteUserRef: MatDialogRef<DeleteFileComponent>;
  unShareFilesRef: MatDialogRef<any>;
  rejectFileRef: MatDialogRef<any>;
  viewFileRef: MatDialogRef<ViewFileDialogComponent>;
  listOfFiles: any;
  sharedFiles: any[] = [];
  notifiedFiles: any[] = [];
  inboxFiles: any[] = [];
  rejectedFiles: any[] = [];
  userDeletedFiles: any[] = [];
  listOfFilesCount: any;
  sharedFilesCount: any;
  notifiedFilesCount: any;
  inboxFilesCount: any;
  public base64: any;
  subscriptionObject: Subscription;
  // Grace period feature
  showGracePeriod = false;
  searchTermInbox: any;
  searchTermShared: any;
  searchTermNotification: any;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private authService: AuthenticationService,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private shareFilesService: ShareFileService,
    private snackBar: MatSnackBar,
    private dataService: SharedDataService,
    public liveNotificationService: LiveNotificationsService,
    private dataShared: SharedDataService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getRealNotifications();
    this.emailValue = this.cookieService.get('email');
    this.userId = localStorage.getItem('USERID');
    this.authService.userList(this.emailValue).subscribe(list => {
      this.listOfFiles = list['payload'];
      this.listOfFilesCount = this.listOfFiles.length;
    });

    // uncomment below line for AutoUpdate data for share File count  -- 11/07/2022
    this.dataService.uploadData$.subscribe(result => {
      this.retrieveSharedFiles();
    });

    this.retrieveSharedFiles();

    this.retrieveViewSharedFiles();

    this.authService.deletedFilesList(this.emailValue).subscribe(list => {
      this.userDeletedFiles = list['payload'];
    });

    this.dataService.userDeletedData$.subscribe(result => {
      if (result) {
        this.authService.deletedFilesList(this.emailValue).subscribe(list => {
          this.userDeletedFiles = list['payload'];
        });
      }
    });

    this.authService.rejectFilesList(this.emailValue).subscribe(list => {
      this.rejectedFiles = list['payload'];
    });
  }

  getRealNotifications() {
    this.subscriptionObject = this.liveNotificationService
      .getNotifications()
      .subscribe((data: any) => {
        this.retrieveViewSharedFiles();
        this.retrieveSharedFiles();
      });
  }

  uploadFile(event: any) {
    this.userUploadRef = this.dialog.open(UploadFileDialogComponent, {
      width: '400px',
      height: '325px',
      disableClose: false
    });
    this.userUploadRef.componentInstance.userEmailId = this.emailValue;
    this.userUploadRef.afterClosed().subscribe(result => {
      if (result !== true && result !== undefined) {
        this.dataService.sendData(result);
      }
    });
  }

  shareFile(filename: any, email: any) {
    const emailId = email;
    this.shareFilesRef = this.dialog.open(ShareFileDialogComponent, {
      width: '400px',
      height: '250px',
      disableClose: true
    });
    this.shareFilesRef.componentInstance.filename = filename;
    this.shareFilesRef.componentInstance.activeShareDialog = 'Active Share Dialog';
    this.shareFilesRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.shareFilesService.listUserSharedFiles(this.emailValue).subscribe(data => {
          if (data) {
            this.sharedFiles = result['payload'];
            this.sharedFilesCount = this.sharedFiles.length;
          }
        });
      }
    });
  }

  deleteFile(deleteKey: any, dataValue: any, index: any) {
    this.deleteUserRef = this.dialog.open(DeleteFileComponent, {
      width: '400px',
      height: '175px',
      disableClose: false
    });
    // this.deleteUserRef.componentInstance.currentUserEmail = this.emailValue;
    // this.deleteUserRef.componentInstance.deleteKey = deleteKey;
    this.deleteUserRef.componentInstance.deleteAction = 'File Delete Action';
    this.deleteUserRef.afterClosed().subscribe(result => {
      if (result === 'Yes') {
        this.listOfFiles.splice(index, 1);
      }
    });
  }

  displaySharingDetails() {
    alert('Coming up next...');
  }

  unShareFile(file: SharedFile) {
    this.dataShared.storage = file;
    this.router.navigate(['/edit-share-detail', file.key])
  }

  viewFile(file: SharedFile) {
    const key = file.key,
      notifyStatus = 'accepted';
    this.authService.notificationStatus(this.emailValue, key, notifyStatus).subscribe(result => {
      if (result) {
        this.liveNotificationService.sendNotification({
          'email': this.emailValue,
          'key': key,
          'status': notifyStatus
        });
        this.retrieveViewSharedFiles();
      }
    }, err => {
      this.deleteUserRef = this.dialog.open(DeleteFileComponent, {
        width: '350px',
        height: '175px',
        disableClose: true
      });
      this.deleteUserRef.componentInstance.deleteAction = 'File is no longer';
    });
  }


  inboxViewFile(file: SharedFile) {
    const filelink: any = file.link;
    const sharedEmail: any = file.email;
    const sharedFilename: any = file.filename;
    const contentType: any = file.contentType;
    const key: any = file.key;
    this.shareFilesService.addBlockSharedFile(this.emailValue, sharedEmail, sharedFilename, key).subscribe(result => {
      const filedata: any = result;
      if (filedata.contentType === 'application/pdf') {
        this.viewFileRef = this.dialog.open<ViewFileDialogComponent, ViewFileDialogConfig>(ViewFileDialogComponent, {
          minWidth: '60vw',
          maxWidth: '95vw',
          maxHeight: '95vh',
          disableClose: false,
          panelClass: 'dialog-panel',
          data: {
            viewerType: 'pdf',
            viewFile: filedata.link,
            isDownloadable: file.downloadable
          }
        });
      } else if (filedata.contentType.match(/^image\/*/i)) {
        fetch("data:image/png;base64," + filedata.link)
        .then(res => res.blob())
        .then(async (blob) => {
          let watermarkfile = await this.shareFilesService.watermarkImage(blob, this.userId);
          watermarkfile = watermarkfile.src.replace('data:image/png;base64,', '');
          this.viewFileRef = this.dialog.open<ViewFileDialogComponent, ViewFileDialogConfig>(ViewFileDialogComponent, {
            minWidth: '20vw',
            maxWidth: '95vw',
            maxHeight: '95vh',
            disableClose: false,
            panelClass: 'dialog-panel',
            data: {
              viewerType: 'image',
              viewFile: watermarkfile,
              viewImageFormat: true,
              isDownloadable: file.downloadable
            }
          });
        });
      } else if (filedata.contentType.match(/^video\/*/i)) {
        this.viewFileRef = this.dialog.open<ViewFileDialogComponent, ViewFileDialogConfig>(ViewFileDialogComponent, {
          minWidth: '20vw',
          maxWidth: '95vw',
          maxHeight: '95vh',
          disableClose: false,
          panelClass: 'dialog-panel',
          data: {
            viewerType: 'video',
            viewFile: filedata.link,
            contentType: filedata.contentType,
            isDownloadable: file.downloadable
          }
        });
      }

      this.liveNotificationService.sendNotification({
        'email': this.emailValue,
        'key': key,
        'status': 'view'
      });

    }, err => {
      this.snackBar.open('Oops, something went wrong', 'Close');
    });
  }

  inboxDownloadFile(file: SharedFile) {
    const key: any = file.key;
    this.authService.downloadFile(key,this.emailValue).subscribe(result => {
      var blob = new Blob([result])
      saveAs(blob, file.filename);
    }, err => {
      this.snackBar.open('Failed to download file ', 'Close')
    });
  }

  rejectFile(file: SharedFile) {
    const key = file.key,
      notifyStatus = 'rejected';
    this.rejectFileRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      height: '175px',
      disableClose: true
    });
    this.rejectFileRef.componentInstance.confirmMessage = 'Are you sure want to reject the shared file?';
    this.rejectFileRef.componentInstance.button = {
      confirm: true,
      confirmText: 'Yes',
      cancel: true
    };
    this.rejectFileRef.componentInstance.key = key;
    this.rejectFileRef.componentInstance.notifyStatus = notifyStatus;
    this.rejectFileRef.afterClosed().subscribe(data => {
      if (data && data['message'] === 'File link') {
        this.notifiedFiles = [];
        this.notifiedFilesCount = 0;
        this.rejectedFiles = [];
        if (data && data['link']) {
          const notifiedArr = data['link'].forEach((item, index) => {
            if (item.AR === '-') {
              this.notifiedFiles.push(item);
              this.notifiedFilesCount += 1;
            } else if (item.AR === 'rejected') {
              this.rejectedFiles.push(item);
              this.snackBar.open('File has been rejected successfully.', 'Close');
            }
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionObject.unsubscribe();
  }

  public retrieveViewSharedFiles() {
    this.shareFilesService.viewSharedFile(this.emailValue).subscribe(data => {
      this.notifiedFiles = [];
      this.notifiedFilesCount = 0;
      this.inboxFiles = [];
      this.inboxFilesCount = 0;
      if (data && data['link']) {
        const notifiedArr = data['link'].forEach((item, index) => {
          item.showGracePeriod = false;
          if (item.expiresAt !== 0) {
            let time = moment(item.expiresAt).diff(moment().utc().format(), 'seconds');
            item.showGracePeriod = true;
            item.timeLeftSec = time;
          }

          if (item.AR === '-') {
            this.notifiedFiles.push(item);
            this.notifiedFilesCount += 1;
          } else if (item.AR === 'accepted') {
            this.inboxFiles.push(item);
            this.inboxFilesCount += 1;
          }
        });
      }
    });
  }

  // UTILS:
  private retrieveSharedFiles() {
    this.shareFilesService.listUserSharedFiles(this.emailValue).subscribe(result => {
      if (result) {
        this.sharedFiles = result['payload'];
        var arr = [];
        this.sharedFiles.forEach(item => {
          var objNew = {};
          var index = arr.findIndex(x => x.key === item.key);
          if(index !== -1){
            item.AR === 'accepted' ? arr[index].totalAccepted++ : arr[index].totalAccepted;
            item.AR === 'rejected' ? arr[index].totalRejected++ : arr[index].totalRejected;
            item.AR === '-' ? arr[index].totalNoResponse++ : arr[index].totalNoResponse;
            arr[index].countShared++;
            arr[index]['usersAccepted'] = item.AR === 'accepted' ? arr[index]['usersAccepted'] + ',' + item.useremail : arr[index]['usersAccepted'];
            arr[index]['usersRejected'] = item.AR === 'rejected' ? arr[index]['usersRejected'] + ',' + item.useremail  : arr[index]['usersRejected'];
            arr[index]['usersNoResponse'] = item.AR === '-' ? arr[index]['usersNoResponse'] + ',' + item.useremail : arr[index]['usersNoResponse'];
          } else {
            objNew['totalAccepted'] = item.AR === 'accepted' ? 1 : 0;
            objNew['totalRejected'] = item.AR === 'rejected' ? 1 : 0;
            objNew['totalNoResponse'] = item.AR === '-' ? 1 : 0;
            objNew['countShared'] = 1;
            objNew['usersAccepted'] = item.AR === 'accepted' ? item.useremail : null;
            objNew['usersRejected'] = item.AR === 'rejected' ? item.useremail : null;
            objNew['usersNoResponse'] = item.AR === '-' ? item.useremail : null;
            arr.push({...objNew, ...item});
          }
        });
        this.sharedFiles = arr;
        this.sharedFilesCount = this.sharedFiles.length;
      }
    });
  }

  setValueToSearchInInbox(term) {
    this.searchTermInbox = term;
  }

  setValueToSearchInShared(term) {
    this.searchTermShared = term;
  }

  setValueToSearchInNotification(term) {
    this.searchTermNotification = term;
  }
}
