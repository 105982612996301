import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-logout-dialog',
    templateUrl: './logout-dialog.component.html',
    styleUrls: ['../delete-file/delete-file.component.css']
})
export class LogoutDialogComponent {
    public userEmailId: any;
    public key: any;
    public notifyStatus: any;
    public confirmMessage: string;
    public title: string;
    public button: ButtonModel;
    constructor(
        public dialogRef: MatDialogRef<LogoutDialogComponent>,
        private cookieService: CookieService,
        private router: Router,
        private auth: AuthenticationService
        ) {
        this.button = {
            confirm: true,
            confirmText: 'Yes'
        };
    }

    onLogout() {
      this.auth.logout();
      this.dialogRef.close('Logged out successfully!');
    }
}

export class ButtonModel {
    confirm?: boolean;
    cancel?: boolean;
    close?: boolean;
    confirmText?: string;
}
