import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import { CuratusAuthGuardService } from 'src/app/AuthGuard/curatus-auth.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  register = [];
  corporates: any;
  individual: any;
  clientField: boolean;
  clientNames: any[];
  registerAccount: any;
  // confirmPassword: any;
  mismatchPassword: boolean;
  hide = true;
  loading = false;

  registerForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public fb: FormBuilder,
    private snackBar: MatSnackBar,
    private auth: CuratusAuthGuardService
  ) {
    this.registerAccount = {};
    this.initForm();
  }

  ngOnInit() {
    this.auth.checkAuthentication();
    this.clientField = true;
    this.corporates = '';
  }

  initForm() {
    this.registerForm = this.fb.group({
      category: ['Individual', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: [null],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{6,}")]],
      confirmPassword: [''],
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  registerUserAuth(register) {
      this.loading = true;
      this.authService.registerUser(this.registerForm.value).subscribe(result => {
          if (result && result['status'] === 200) {
            this.snackBar.open(result['message'], 'Close');
            this.router.navigate(["/login"]);
          }
        },
        err => {
          this.loading = false;
          this.snackBar.open(err.message, 'Close');
        }
      );
  }
}
