import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {CookieService} from 'ngx-cookie';
import {LiveNotificationsService} from 'src/app/services/live-notifications.service';
import {ShareFileService} from '../../../services/share-file.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['../delete-file/delete-file.component.css']
})
export class ConfirmDialogComponent {
    public userEmailId: any;
    public key: any;
    public notifyStatus: any;
    public confirmMessage: string;
    public title: string;
    public button: ButtonModel;
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private authService: AuthenticationService,
        private shareFilesService: ShareFileService,
        private cookieService: CookieService,
        private liveNotificationsService: LiveNotificationsService
        ) {
        this.button = {
            confirm: true,
            confirmText: 'Yes'
        };
    }

    onReject() {
        this.authService.notificationStatus(this.userEmailId, this.key, this.notifyStatus).subscribe(result => {
            if (result) {
                this.liveNotificationsService.sendNotification({
                  'email': this.userEmailId,
                  'key': this.key,
                  'status': this.notifyStatus
                });
                this.shareFilesService.viewSharedFile(this.userEmailId).subscribe(data => {
                   if (data) {
                    this.dialogRef.close(data);
                   }
                });
            }
        });
       // this.dialogRef.close('reject');
    }

    ngOnInit() {
        this.userEmailId = this.cookieService.get('email');
    }
}

export class ButtonModel {
    confirm?: boolean;
    cancel?: boolean;
    close?: boolean;
    confirmText?: string;
}
