import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { SocketioService } from './socketio.service';

@Injectable({
  providedIn: 'root'
})
export class LiveNotificationsService {

  constructor(
    public wsService: SocketioService,
    private socket: Socket
  ) { }

  sendNotification(message: any) {
    this.wsService.emit('notification', message);
  }

  getNotifications() {
    return this.wsService.listen('notification-new');
  }

}
