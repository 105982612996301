import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GracePeriodService} from '../../../services/grace-period.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-new-user-dialog',
    templateUrl: './new-user-dialog.component.html',
    styleUrls: ['./new-user-dialog.component.scss']
})

export class NewUserDialogComponent implements OnInit {

    userEmailId: any;
    repeat: any;
    mismatchPassword: boolean;
    hide = true;
    registerForm: FormGroup = new FormGroup({
      category: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(null),
      email: new FormControl('', [Validators.required, Validators.email])
    });
    registerAccount: any;
    client_email: string;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<NewUserDialogComponent>,
        public gracePeriodService: GracePeriodService,
        private cookieService: CookieService,
        private snackBar: MatSnackBar,
        private authService: AuthenticationService,
        private router: Router
    ) {
      this.registerAccount = {};
    }

    ngOnInit() {
      this.userEmailId = this.cookieService.get('email');
      this.registerAccount.category = 'Organization User';
    }

    addNewUser(register) {
      this.registerAccount.client_email = this.userEmailId;
      this.authService.addUserByAdmin(this.registerAccount).subscribe(result => {
        if (result && result['status'] == 200 ) {
          this.dialogRef.close();
          this.snackBar.open(result['message'], 'Close');
        }
      },
      err => {
        const errorMessage = err.error;
        this.snackBar.open(errorMessage.message, 'Close');
      });
    }
}
