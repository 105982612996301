import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'upload-file-dialog',
    templateUrl: './upload-file-dialog.component.html',
    styleUrls: ['./upload-file-dialog.component.scss']
})

export class UploadFileDialogComponent implements OnInit {

    fileDetails: any;
    filePath: any;
    loader: boolean;
    userEmailId: any;
    requestedUploadFileName: string;
    listFileType = 'image/*,video/*,audio/*,.pdf';

    constructor(
        private authService: AuthenticationService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<UploadFileDialogComponent>,
        private snackBar: MatSnackBar
    ) {
        this.fileDetails = {};
    }

    ngOnInit() {
        this.fileDetails.title = '';
        this.fileDetails.description = '';
    }

    selectedPath(uploader: any) {
        this.filePath = this.getActualFile(uploader);
    }

    submit(event: any) {
        if (event) {
            this.loader = true;
        }
        const formData = new FormData();
        formData.append('filename', this.filePath, this.filePath.name);
        formData.append('title', this.fileDetails.title);
        formData.append('description', this.fileDetails.description);
        this.authService.uploadFile(formData, this.userEmailId).subscribe(data => {
            if (data) {
                if (data && data.type && data.type === 4) {
                   const responseData = data && data['body'] && data['body']['data'] ? data['body']['data'] : '';
                   this.snackBar.open('File has been uploaded successfully.', 'Close')
                   this.fileDetails.title = '';
                   this.fileDetails.description = '';
                   this.fileDetails.filename = '';
                   this.loader = false;
                   this.dialogRef.close(responseData);
                }
            }
        });
    }

    private getActualFile(uploader) {
        return uploader.files[0];
    }
}
