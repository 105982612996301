import { Component, OnInit } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { CuratusAuthGuardService } from 'src/app/AuthGuard/curatus-auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  resizeStyle: object = {};
  collapseSidebar = true;

  constructor(
    private auth: CuratusAuthGuardService
  ) {

  }

  ngOnInit() {
    this.resizeStyle = {
      "max-width": `30%`,
    };
    /* 100% Height fix from https://stackoverflow.com/a/57800404 */
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  /**
    * Validates resize positions
    * (used for drawer/sidenav width)
    * @param event
    */
  resizeValidate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 50;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }

  /**
   * Finilizes resize positions
   * (used for drawer/sidenav width)
   * @param event
   */
    onResizeEnd(event: ResizeEvent): void {
      this.resizeStyle = {
                      // enable/disable these per your needs
        //position: 'fixed',
        //left: `${event.rectangle.left}px`,
        //top: `${event.rectangle.top}px`,
        //height: `${event.rectangle.height}px`,
        width: `${event.rectangle.width}px`,
      };
    };

    public isAuthenticated() {
      return this.auth.isAuthenticated();
    }

}
