import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegisterComponent} from './Screens/authentication/register/register.component';
import {LoginComponent} from './Screens/authentication/login/login.component';
import {AuthenticationService} from './services/authentication.service';
import {SharedCommonModule} from './shared/shared.module';
import {GlobalApiIntegration} from './services/global-url.service';
import {CookieModule} from 'ngx-cookie';
import {CuratusNavbarComponent} from './Screens/layouts/navbar/curatus-navbar.component';
import {CuratusAuthGuardService} from './AuthGuard/curatus-auth.service';
import {TokenInterceptorService} from './services/token-interceptor.service';
import {MaterialModule} from '../app/shared/material.module';
import {MAT_RADIO_DEFAULT_OPTIONS, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatDialogRef} from '@angular/material';
import {AdvancedUsersComponent} from './Screens/authentication/advanced-users/advanced-users.component';
import {SidebarComponent} from './Screens/layouts/sidebar/sidebar.component';
import {CuratusAdminModule} from './Screens/curatus-admin/curatus-admin.module';
import {DeleteFileModule} from './shared/dialogs/delete-file/delete-file.module';
import {ConfirmDialogComponent} from './shared/dialogs/confirm-dialog/confirm-dialog.component';
import {SetRetentionModule} from './shared/dialogs/set-retention/set-retention.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LoaderService} from './services/loader.service';
import {LoaderInterceptor} from './interceptors/loader.interceptor';
import {LoaderComponent} from './shared/loader/loader.component';
import {NotfoundComponent} from './Screens/layouts/notfound/notfound.component';
import {LogoutDialogComponent} from './shared/dialogs/logout-dialog/logout-dialog.component';
import {DownloadDialogComponent} from './shared/dialogs/download-dialog/download-dialog.component';

// Socket imports
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {DeleteFileGracePeriodComponent} from './shared/dialogs/delete-file-grace-period/delete-file-grace-period.component';
import {CountdownGlobalConfig, CountdownModule} from 'ngx-countdown';
import {GracePeriodBannerComponent} from './shared/grace-period-banner/grace-period-banner.component';
import {InboxFileComponent} from './shared/inbox-file/inbox-file.component';
import {DashboardComponent} from './Screens/dashboard/dashboard.component';
import {DashboardColumnHeaderComponent} from './shared/dashboard-column-header/dashboard-column-header.component';
import {UploadFileDialogModule} from './shared/dialogs/upload-file-dialog/upload-file-dialog.module';
import {ShareFileDialogComponent} from './shared/dialogs/share-file-dialog/share-file-dialog.component';
import {FileListFilterPipe, UserListFilterPipe} from './shared/user-list.pipe';
import {UploadedFileComponent} from './shared/uploaded-file/uploaded-file.component';
import {SharedFileComponent} from './shared/shared-file/shared-file.component';
import {FileNotificationComponent} from './shared/file-notification/file-notification.component';
// Admin imports
import {CorporateUserComponent} from './shared/admin-components/corporate-user-list/corporate-user-list.component';
import {CuratusMvpAdminComponent} from './Screens/curatus-mvp-admin/curatus-mvp-admin.component';
import {CorporateDeletedFiles} from './shared/admin-components/corporate-deleted-files/corporate-deleted-files.component';
import {CorporateRejectedFiles} from './shared/admin-components/corporate-rejected-files/corporate-rejected-files.component';
import {UserListAdminComponent} from './Screens/user-list-admin/user-list-admin.component';
import {RegisterUserListAdminComponent} from './Screens/register-user-list-admin/register-user-list-admin.component';
import {NewUserDialogComponent} from './shared/dialogs/new-user-dialog/new-user-dialog.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {SearchSwimlanesComponent} from './shared/search-swimlanes/search-swimlanes.component';
import {EditShareDetailComponent} from './Screens/edit-share-detail/edit-share-detail.component';
import {ForgotPasswordDialogComponent} from './shared/dialogs/forgot-password/forgot-password-dialog.component';
import {ResetPasswordComponent} from './Screens/authentication/reset-password/reset-password.component';
import {AuditFileComponent} from './shared/audit-file/audit-file.component';
import {ClipboardModule} from 'ngx-clipboard';
import {EditSettingsComponent} from './Screens/edit-settings/edit-settings.component';

import {ResizableModule} from 'angular-resizable-element';
import {MainComponent} from './Screens/layouts/main/main.component';
import {ViewUserFilesComponent} from './Screens/view-user-files/view-user-files.component';
import {UnshareDialogComponent} from './shared/dialogs/unshare-dialog/unshare-dialog.component';
import {ViewFileDialogComponent} from './shared/dialogs/view-file-dialog/view-file-dialog.component';
import {ImageViewerModule} from 'lacuna-image-viewer';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {NgxDocViewerModule} from 'ngx-doc-viewer';

const config: SocketIoConfig = { url: '', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    AdvancedUsersComponent,
    CuratusNavbarComponent,
    SidebarComponent,
    ShareFileDialogComponent,
    UserListFilterPipe,
    FileListFilterPipe,
    ConfirmDialogComponent,
    UnshareDialogComponent,
    LoaderComponent,
    NotfoundComponent,
    LogoutDialogComponent,
    DownloadDialogComponent,
    DeleteFileGracePeriodComponent,
    InboxFileComponent,
    GracePeriodBannerComponent,
    DashboardComponent,
    DashboardColumnHeaderComponent,
    UploadedFileComponent,
    SharedFileComponent,
    FileNotificationComponent,
    CuratusMvpAdminComponent,
    CorporateUserComponent,
    CorporateDeletedFiles,
    CorporateRejectedFiles,
    UserListAdminComponent,
    RegisterUserListAdminComponent,
    NewUserDialogComponent,
    SearchSwimlanesComponent,
    EditShareDetailComponent,
    ForgotPasswordDialogComponent,
    ResetPasswordComponent,
    AuditFileComponent,
    EditSettingsComponent,
    MainComponent,
    ViewUserFilesComponent,
    ViewFileDialogComponent
  ],
  entryComponents: [
    ShareFileDialogComponent,
    ConfirmDialogComponent,
    UnshareDialogComponent,
    DownloadDialogComponent,
    LogoutDialogComponent,
    DeleteFileGracePeriodComponent,
    NewUserDialogComponent,
    ForgotPasswordDialogComponent,
    ViewFileDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    CuratusAdminModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedCommonModule,
    CookieModule.forRoot(),
    UploadFileDialogModule,
    DeleteFileModule,
    SetRetentionModule,
    MaterialModule,
    FlexLayoutModule,
    SocketIoModule.forRoot(config),
    CountdownModule,
    Ng2SearchPipeModule,
    ClipboardModule,
    ResizableModule,
    ImageViewerModule.forRoot(),
    NgxExtendedPdfViewerModule,
    NgxDocViewerModule
  ],
  providers: [
    AuthenticationService,
    GlobalApiIntegration,
    CuratusAuthGuardService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: CountdownGlobalConfig }
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
