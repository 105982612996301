import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LiveNotificationsService } from 'src/app/services/live-notifications.service';

export class ButtonModel {
  confirm?: boolean;
  cancel?: boolean;
  close?: boolean;
  confirmText?: string;
}

@Component({
    selector: 'app-delete-grace-period-dialog',
    templateUrl: './delete-file-grace-period.component.html',
    styleUrls: ['../delete-file/delete-file.component.css']
})
export class DeleteFileGracePeriodComponent {
    public userEmailId: any;
    public filename: any;
    public key: any;
    public notifyStatus: any;
    public confirmMessage: string;
    public title: string;
    public button: ButtonModel;
    constructor(
        public dialogRef: MatDialogRef<DeleteFileGracePeriodComponent>,
        private cookieService: CookieService,
        private router: Router,
        private authService: AuthenticationService,
        public liveNotificationService: LiveNotificationsService,
        ) {
        this.button = {
            confirm: true,
            confirmText: 'Yes'
        };
    }

    onRemove() {
      this.authService.deleteFileGracePeriod(this.userEmailId, this.key,this.filename).subscribe((result) => {
        console.log(result);
        this.liveNotificationService.sendNotification({
          'email': this.userEmailId,
          'key': this.key,
          'status': 'removed'
        });
        this.dialogRef.close('Remove out successfully!');
      }, error => {
        this.dialogRef.close({status: 405, message: error.message});
      });
    }
}

