import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'corporate-user',
    templateUrl: './corporate-user-list.component.html',
    styleUrls: ['./corporate-user-list.component.scss']
})

export class CorporateUserComponent {


  @Input() user: any;
  @Output() viewUserFilesEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  public handleViewUserFiles() {
    this.viewUserFilesEvent.emit(this.user);
  }

}
