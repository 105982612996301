import {Component, Input} from '@angular/core';

@Component({
    selector: 'dashboard-column-header',
    templateUrl: './dashboard-column-header.component.html',
    styleUrls: ['./dashboard-column-header.component.scss']
})

export class DashboardColumnHeaderComponent {
  @Input() fileCount = 0;
  @Input() title;
  @Input() placeholder;

  constructor() {
  }
}
