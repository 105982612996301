import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  mismatchPassword: boolean;
  confirmPassword: any;
  resetAccount: any;
  hide = true;
  email: any;
  token: any;

  matcher = new MyErrorStateMatcher();

  resetForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.resetAccount = {};
  }

  ngOnInit() {
    this.initForm();
    this.route.queryParams.subscribe(params => {
      if(Object.entries(params).length === 0){
        this.router.navigateByUrl('/login');
      }
      this.resetForm.get('emailReset').setValue(params['email']);
      this.resetForm.get('token').setValue(params['token']);
      if(params['category'] === 'Individual') {
        var activateUser = {
          email: params['email'],
          token: params['token']
        }
        this.activateAutomatically(activateUser);
      }
    });
  }

  initForm() {
    this.resetForm = this.fb.group({
      emailReset: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{6,}")]),
      confirmPassword: new FormControl(''),
      token:  new FormControl('', [Validators.required]),
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  resetPasswordSubmit(resetObj) {
      const objReset = {
        'email': resetObj.emailReset,
        'password': resetObj.password,
        'confirmPassword': resetObj.confirmPassword,
        'token': resetObj.token
      };
      this.authService.resetPassword(objReset).subscribe(result => {
          if (result && result['status'] === 200) {
            this.snackBar.open(result['message'], 'Close');
            this.router.navigate(["/login"]);
          }
        },
        err => {
          const errorMessage = err.error;
          this.snackBar.open(errorMessage.message, 'Close');
        }
      );
  }

  activateAutomatically(activateUser) {
    this.authService.activateUser(activateUser).subscribe(result => {
      if (result && result['status'] === 200) {
        this.snackBar.open(result['message'], 'Close');
        this.router.navigate(["/login"]);
      }
    },
    err => {
      const errorMessage = err.error;
      this.snackBar.open(errorMessage.message, 'Close');
      this.router.navigate(["/login"]);
    }
  );
  }

}
