import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie';
import { MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material';
import { LiveNotificationsService } from 'src/app/services/live-notifications.service';
import { GracePeriodService } from '../../../services/grace-period.service';
import {ShareFileService} from '../../../services/share-file.service';

@Component({
    selector: 'app-share-file-dialog',
    templateUrl: './share-file-dialog.component.html',
    styleUrls: ['../upload-file-dialog/upload-file-dialog.component.scss']
})
export class ShareFileDialogComponent implements OnInit, AfterViewInit {

    displayedColumns = ['select', 'userEmailAddress', 'userType', 'gracePeriod'];
    dataSource = new MatTableDataSource();
    selection = new SelectionModel<Element>(true, []);
    usersFormGroup: FormGroup;
    unShareFormGroup: FormGroup;
    searchTerm: any;
    shareUserList: any[];
    shareUserListCount: any;
    shareConsultantList: any[];
    shareConsultantListCount: any;
    unShareUserList: any[];
    selectedUsersArray: any[];
    selectedConsultantArray: any[];
    userEmailId: any;
    shareFiles: any;
    filename: any;
    activeShareDialog: any;
    userEmail: any;
    emailId: any;
    fileNames: any;
    authority: any;
    key: any;
    downloadable: boolean = false;
    toggle: boolean;
    consultantToggle: boolean;
    finalListArr: MatTableDataSource<any>;
    selectedArr: any[] = [];
    defaultHrs: number = 72;
    gracePeriodArray = [];
    fileUserCategory: any;

    constructor(
        private shareFilesService: ShareFileService,
        private cookieService: CookieService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ShareFileDialogComponent>,
        private snackBar: MatSnackBar,
        public liveNotificationsService: LiveNotificationsService,
        private gracePeriodService: GracePeriodService
    ) {
        this.shareFiles = {};
    }

    async ngOnInit() {
        this.userEmailId = this.cookieService.get('email');
        this.authority = this.cookieService.get('role');

        this.usersFormGroup = this.formBuilder.group({
            shareUserList: this.formBuilder.array([]),
            shareConsultantList: this.formBuilder.array([])
        });

        this.unShareFormGroup = this.formBuilder.group({
            unShareUserList: this.formBuilder.array([])
        });

        await this.getAllGracePeriod();
        if (this.activeShareDialog === 'Active Share Dialog') {
            this.shareFilesService.shareUserList(this.userEmailId, this.filename, this.authority, this.key).subscribe(list => {
                if (list && list['corporateUsers']) {
                    const shareUserListArr = list['corporateUsers'];
                    const shareArr = [];
                    shareUserListArr.map((item, index) => {

                        // Added logic to grace period list feature
                        const emailsUser = item.split('||');

                        // Admin can re-share Client’s shared files with other Org users
                        var skipClientsEmails = false;
                        // User owner should not appear in the list of users the admin can reshare this file with
                        var skipOwnerEmail = false;
                        if(this.authority === 'Organization Admin') {
                          if(this.fileUserCategory === 'Organization Client' && emailsUser[2] === 'Organization Client') skipClientsEmails = true;
                          if(emailsUser[0] === this.userEmail) skipOwnerEmail = true;
                        }

                        if (emailsUser[0] !== this.userEmailId && !skipOwnerEmail && !skipClientsEmails ) {
                            let data = this.getGracePeriod(emailsUser[1]);
                            let showHrs = this.defaultHrs;
                            if (data !== undefined) {
                              showHrs = data.hours;
                            }
                            shareArr.push({
                                userEmailAddress: emailsUser[0],
                                userType: emailsUser[2], //'Individual',
                                gracePeriod: showHrs
                            });
                        }
                    });
                    this.shareUserList = shareArr;
                    this.shareUserListCount = this.shareUserList.length;
                }
                if (list && list['individualUsers']) {
                    const shareConsultantListArr = list['individualUsers'];
                    const shareConsultantArr = [];
                    shareConsultantListArr.map((item, index) => {
                        if (item !== this.userEmailId) {
                            shareConsultantArr.push({
                                userEmailAddress: item,
                                userType: 'Individual', // 'Consultant',
                                gracePeriod: 0
                            });
                        }
                    });
                    this.shareConsultantList = shareConsultantArr;
                    this.shareConsultantListCount = this.shareConsultantList.length;
                }
                const userListArr = this.shareUserList.concat(this.shareConsultantList);
                this.finalListArr = new MatTableDataSource(userListArr);
            });
        } else if (this.activeShareDialog === 'Active UnShare Dialog') {
            this.shareFilesService.unShareUserList(this.userEmailId, this.fileNames, this.key).subscribe(list => {
                const unShareUserListArr = list['payload'];
                const unShareArr = [];
                unShareUserListArr.map((item, index) => {
                    unShareArr.push({
                        email: item,
                        isChecked: false
                    });
                });
                this.unShareUserList = unShareArr;
            });
        }
    }

    // Grace Period Feature
    getAllGracePeriod() {
      this.gracePeriodService.getGracePeriodList().subscribe(result => {
        if (result) {
          this.gracePeriodArray = result['gracePeriods'];
        }
      });
    }

    getGracePeriod(email) {
      return this.gracePeriodArray.find(x => x.client.email === email);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.finalListArr.filter = filterValue.trim().toLowerCase();
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.finalListArr.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.selectedArr = [];
        this.isAllSelected() ?
            this.selection.clear() :
            this.finalListArr.data.forEach((row) => {
                this.selection.select(row);
                if (row) {
                this.selectedArr.push(row);
                }
            });
    }

    setDownloadable(event) {
        this.downloadable = event.checked;
    }

    singleRowSelected(event, row, selection) {
        if (event && event.checked === true) {
            this.selection.select(row);
            this.selectedArr.push(row);
        } else if (event && event.checked === false) {
            this.selectedArr.map((item, index) => {
                if (event.source.value === item.userEmailAddress) {
                   this.selectedArr.splice(index, 1);
                   if(this.selectedArr.length === 0) {
                     this.masterToggle();
                   }
                }
            });
        } else {
            return null;
        }
    }

    onChange(event: any) {
        const interests = this.usersFormGroup.get('shareUserList') as FormArray;
        if (event.checked) {
          interests.push(new FormControl(event.source.value));
        } else {
          const i = interests.controls.findIndex(x => x.value === event.source.value);
          interests.removeAt(i);
        }
    }

    toggleItem(item: any, event: any) {
        item.isChecked = !item.isChecked;
        this.toggle = this.shareUserList.every(data => data.isChecked);
        const interests = this.usersFormGroup.get('shareUserList') as FormArray;

        if (event.checked) {
          interests.push(new FormControl(event.source.value));
        } else {
          const i = interests.controls.findIndex(x => x.value === event.source.value);
          interests.removeAt(i);
        }
    }

    toggleAll(event: any) {
        this.toggle = !this.toggle;
        this.shareUserList.forEach((item) => {
            item.isChecked = this.toggle;
            const interests = this.usersFormGroup.get('shareUserList') as FormArray;
            if (item.isChecked) {
                interests.push(new FormControl(item.email));
            } else {
                const i = interests.controls.findIndex(x => x.value === event.source.value);
                interests.removeAt(i);
            }
        });
    }

    toggleConsultantItem(item: any, event: any) {
        item.isChecked = !item.isChecked;
        this.consultantToggle = this.shareConsultantList.every(data => data.isChecked);
        const interests = this.usersFormGroup.get('shareConsultantList') as FormArray;
        if (event.checked) {
          interests.push(new FormControl(event.source.value));
        } else {
          const i = interests.controls.findIndex(x => x.value === event.source.value);
          interests.removeAt(i);
        }
    }

    toggleAllConsultant(event: any) {
        this.consultantToggle = !this.consultantToggle;
        this.shareConsultantList.forEach((item) => {
            item.isChecked = this.consultantToggle;
            const interests = this.usersFormGroup.get('shareConsultantList') as FormArray;
            if (item.isChecked) {
                interests.push(new FormControl(item.email));
            } else {
                const i = interests.controls.findIndex(x => x.value === event.source.value);
                interests.removeAt(i);
            }
        });
    }

    toggleUnsharetItem(item: any, event: any) {
        item.isChecked = !item.isChecked;
        this.consultantToggle = this.unShareUserList.every(data => data.isChecked);
        const interests = this.unShareFormGroup.get('unShareUserList') as FormArray;
        if (event.checked) {
          interests.push(new FormControl(event.source.value));
        } else {
          const i = interests.controls.findIndex(x => x.value === event.source.value);
          interests.removeAt(i);
        }
    }

    toggleUnshareAll(event: any) {
        this.consultantToggle = !this.consultantToggle;
        this.unShareUserList.forEach((item) => {
            item.isChecked = this.consultantToggle;
            const interests = this.unShareFormGroup.get('unShareUserList') as FormArray;
            if (item.isChecked) {
                interests.push(new FormControl(item.email));
            } else {
                const i = interests.controls.findIndex(x => x.value === event.source.value);
                interests.removeAt(i);
            }
        });
    }

    onChangeUnShare(event: any) {
        const interests = <FormArray>this.unShareFormGroup.get('unShareUserList') as FormArray;
        if (event.checked) {
          interests.push(new FormControl(event.source.value));
        } else {
          const i = interests.controls.findIndex(x => x.value === event.source.value);
          interests.removeAt(i);
        }
    }

    shareFilesList(event: any) {
        if (event === 'Active Share Dialog') {
            const shareFinalArr = [];
            this.selectedArr.map((item) => {
                if (item && item.userEmailAddress) {
                    shareFinalArr.push({email: item.userEmailAddress, userType: item.userType});
                }
            });
            this.shareFilesService.shareWithUsers(this.userEmailId, this.filename, shareFinalArr, this.key, this.downloadable).subscribe(result => {
                if (result) {
                  this.liveNotificationsService.sendNotification({
                    'email': this.userEmailId,
                    'filename': this.filename,
                    'useremail': shareFinalArr,
                    'key': this.key,
                    downloadable: this.downloadable
                  });
                  this.snackBar.open('File has been shared successfully.', 'Close');
                  this.dialogRef.close(result);
                }
            });
        }
    }

    unShareFilesList() {
        const unShareUsersArray = this.unShareFormGroup.value.unShareUserList;
        this.shareFilesService.unShareFile(unShareUsersArray, this.userEmailId, this.key, this.filename).subscribe(result => {
            if (result) {
                this.liveNotificationsService.sendNotification({
                  'email': this.userEmailId,
                  'useremail': unShareUsersArray,
                  'key': this.key
                });
                this.dialogRef.close(result);
            }
        });
    }

    ngAfterViewInit() {

    }
}
