import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NewUserDialogComponent } from 'src/app/shared/dialogs/new-user-dialog/new-user-dialog.component';

@Component({
  selector: 'app-user-list-admin',
  templateUrl: './user-list-admin.component.html',
  styleUrls: ['./user-list-admin.component.scss']
})
export class UserListAdminComponent implements OnInit {

  displayedColumns: string[] = ['email', 'first_name', 'date', 'category', 'star'];
  dataSource = [];

  emailValue: any;
  corporateUsers: any;

  addNewUserRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.emailValue = this.cookieService.get('email');
    this.getListOfUsers();
  }

  getListOfUsers() {
    this.authService.clientList(this.emailValue).subscribe(list => {
      this.corporateUsers = list['data'];
      this.dataSource = list['data'];
    });
  }

  goToDashboard() {
    this.router.navigateByUrl('/curatus-mvp-admin');
  }

  forgotPasswordResend(item) {
    this.authService.forgotPassword(item).subscribe(result => {
      if (result && result['status'] == 200 ) {
        this.snackBar.open(result['message'], 'Close');
      }
    },
    err => {
      const errorMessage = err;
      this.snackBar.open(errorMessage.message, 'Close');
    });
  }

  addUser() {
    this.addNewUserRef = this.dialog.open(NewUserDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: false
    });
    this.addNewUserRef.componentInstance.client_email = this.emailValue;
    this.addNewUserRef.afterClosed().subscribe(result => {
      this.getListOfUsers();
    });
  }

}
