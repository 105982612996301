import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CountdownConfig} from "ngx-countdown";
import { LiveNotificationsService } from 'src/app/services/live-notifications.service';

const MINUTES = 1000 * 60;
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];
@Component({
    selector: 'grace-period-banner',
    templateUrl: './grace-period-banner.component.html',
    styleUrls: ['./grace-period-banner.component.scss']
})

export class GracePeriodBannerComponent {
  @Input() file;
  @Output() gracePeriodExpiredEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() textMessage: String = 'This file is pending deletion and will be removed in:';

  config: CountdownConfig = {};

  constructor(public liveNotificationService: LiveNotificationsService) {
  }

  ngOnInit () {
    this.config = {
      leftTime: this.file.timeLeftSec,
      format: 'HH:mm:ss',
      formatDate: ({ date, formatStr }) => {
        let duration = Number(date || 0);
        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current.indexOf(name) !== -1) {
            const v = Math.floor(duration / unit);
            duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          return current;
        }, formatStr);
      },
    };
  }

  public handleEvent(data) {
    if (data.status === 3) {
      this.liveNotificationService.sendNotification({
        'email': this.file.email,
        'key': this.file.key,
        'status': 'expiration'
      });
      this.gracePeriodExpiredEvent.emit(this.file);
    }
  }
}
