import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SharedFile} from "../../global";

@Component({
    selector: 'audit-file',
    templateUrl: './audit-file.component.html',
    styleUrls: ['./audit-file.component.scss']
})

export class AuditFileComponent implements OnInit {
  @Input() auditFile: any;

  constructor() {
  }

  ngOnInit() {
  }

  start_and_end(str) {
    if (str? str.length > 50:false) {
      return str.substr(0, 4) + '...' + str.substr(str.length-4, str.length);
    }
    return str;
  }
}
